<div role="alert" class="alert alert-{{ type }} reset-form-success alert-dismissible widget-alert-box">
  @if (dismissableButton) {
    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  }
  @if (title) {
    <span class="alert-title">{{ title }} <br>
    <hr/></span>
  }
  @if (isMultipleMessages) {
    <ul class="ps-3">
      @for (message of text; track message) {
        <li>
          <span [innerHtml]="message | trustHtml: 'html'"></span>
        </li>
      }
    </ul>
  }
  @if (!isMultipleMessages) {
    <span [innerHtml]="text | trustHtml: 'html'"></span>
  }
</div>
