import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceComma',
  standalone: true
})
export class ReplaceCommaPipe implements PipeTransform {
  transform(value: any) {
    let num = 0;
    num = parseFloat(value.toString().replace(',', '.').replace(' ', ''));
    return num;
  }
}
