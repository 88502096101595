import { NgClass } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, FormsModule, NgForm } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerInputEvent, MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { RouterLink } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { environment } from '../../../../environments/environment';
import { DATE_FORMAT, DATE_FORMATS, DATE_ISO_FORMAT } from '../../../constants/date.constants';
import { MIME_TYPES, WIDGET_CONSTANTS } from '../../../constants/widget-constants';
import { CustomFieldFileUploadModel, CustomFieldOptions, CustomFieldsDao } from '../../../db-models/appointment-custom-fields-dao';
import { CustomEventService } from '../../../services/custom-event.service';
import { CustomFieldService } from '../../../services/custom-field.service';
import { FormsService } from '../../../services/forms.service';
import { LoggerService } from '../../../services/logger.service';
import { UtilService } from '../../../services/util.service';
import { ImageModalComponent } from '../../../shared/modals/image-modal/image-modal.component';
import { YoutubeModalComponent } from '../../../shared/modals/youtube-modal/youtube-modal.component';
import { IsEmptyStringPipe } from '../../../shared/pipes/is-empty-string.pipe';
import { TranslationPipe } from '../../../shared/pipes/translation.pipe';
import { TrustHtmlPipe } from '../../../shared/pipes/trust-html.pipe';
import { TypeofPipe } from '../../../shared/pipes/typeof.pipe';
import { CwCheckboxComponent } from '../theme/cw-checkbox/cw-checkbox.component';
import { CwRadioButtonComponent } from '../theme/cw-radio-button/cw-radio-button.component';

@Component({
  selector: 'app-custom-field',
  templateUrl: './custom-field.component.html',
  styleUrls: ['./custom-field.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  standalone: true,
  imports: [NgClass, FormsModule, NgSelectModule, CwCheckboxComponent, FontAwesomeModule, CwRadioButtonComponent, MatFormFieldModule, MatInputModule, MatDatepickerModule, IsEmptyStringPipe, TranslateModule, TranslationPipe, TrustHtmlPipe, TypeofPipe, RouterLink],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS }
  ]
})

export class CustomFieldComponent implements OnInit {

  private translate = inject(TranslateService);
  private customFieldService = inject(CustomFieldService);
  private formsService = inject(FormsService);
  private utilService = inject(UtilService);
  private elementRef = inject(ElementRef);
  private matDialog = inject(MatDialog);
  private customEventService = inject(CustomEventService);

  @Input() field: CustomFieldsDao;
  @Input() lang: string;
  @Input() customFieldValues: {
    [key: number]: {
      type: string, value: any | any[], option_values: {
        custom_field_option_id: number, custom_field_option_value: string,
      }[]
    } | any
  };
  @Input() customFieldValuesLSNameKey: string;
  @Input() widgetType: string;

  @Output() radioButtonChangeEvent = new EventEmitter<{
    customField: CustomFieldsDao,
    customFieldOption: CustomFieldOptions,
    oldCustomFieldDependencyIds: number[],
    removeOldCustomFieldDependencyIds: boolean,
  }>();
  @Output() checkboxChangeEvent = new EventEmitter<{
    customField: CustomFieldsDao,
    checked: boolean,
  }>();

  readonly deployUrl = environment.deployUrl;
  readonly widgetConstants = WIDGET_CONSTANTS;
  readonly maxFileSize = 1048576 * 5; // 5MB
  readonly dateFormat = DATE_FORMAT;
  readonly dateFormatIso = DATE_ISO_FORMAT;

  selectedDate: Date;
  youtubeInfoUrlDynamicElements: any[] = [];
  customSearchFn1 = this.customSearchFn.bind(this);
  waitForFileUpload = false;
  protected minimumPossibleDateForDatePicker: Date;

  ngOnInit(): void {
    this.translate.onLangChange.subscribe(lang => this.lang = lang.lang);
    setTimeout(() => this.initCustomField(), 500);

    // handle past date selection option for date field
    this.field.type === 'date' && !this.field.allow_past_date_selection && (this.minimumPossibleDateForDatePicker = new Date());
  }

  initCustomField() {
    if (this.field.type === 'file') {
      this.field.uploadedFiles = [];
      this.field.customSupportedMimeType = '';
      if (this.field.supported_mime_type) {
        if (this.field.supported_mime_type.indexOf(',') === -1) {
          if (this.field.supported_mime_type === 'image') {
            this.field.customSupportedMimeType = MIME_TYPES.IMAGE;
          } else if (this.field.supported_mime_type === 'pdf') {
            this.field.customSupportedMimeType = MIME_TYPES.DOC;
          }
        } else {
          const types: string[] = (<string>this.field.supported_mime_type).split(',');
          for (const type of types) {
            if (type === 'image') {
              this.field.customSupportedMimeType = this.field.customSupportedMimeType + MIME_TYPES.IMAGE + ',';
            } else if (type === 'pdf') {
              this.field.customSupportedMimeType = this.field.customSupportedMimeType + MIME_TYPES.DOC + ',';
            }
          }
        }
      } else {
        this.field.customSupportedMimeType = '';
      }

      if (this.customFieldValues[this.field.id]?.file_ids?.length > 0) {
        this.getFilesByFileIds(this.customFieldValues[this.field.id].file_ids);
      }

    } else if (this.field.type === 'checkbox') {
      if (this.customFieldValues[this.field.id]) {
        const event: any = {
          target: {
            checked: true
          }
        };
        this.onSelectCheckbox(event, this.field);
      } else {
        const event: any = {
          target: {
            checked: false
          }
        };
        this.onSelectCheckbox(event, this.field);
      }
    } else if (this.field.type === 'radio') {
      if (this.customFieldValues[this.field.id]) {
        const foundedCustomFieldOptions: CustomFieldOptions = this.field.custom_field_options.find((item: CustomFieldOptions) => {
          return Number(item.id) === Number(this.customFieldValues[this.field.id]);
        });
        if (foundedCustomFieldOptions) {
          this.onRadioValueChanged(foundedCustomFieldOptions, false);
        }
      }
    } else if (this.field.type === 'date') {
      if (this.customFieldValues[this.field.id]) {
        if (this.field.format) {
          this.selectedDate = moment(this.customFieldValues[this.field.id], this.field.format.toUpperCase()).toDate();
        } else {
          this.selectedDate = moment(this.customFieldValues[this.field.id]).toDate();
        }
      }
    } else if (this.field.type === 'information') {
      this.customFieldValues[this.field.id] = 'true'
    } else if (this.field.type === 'select') {
      if (this.field?.is_multiple_select && !(this.customFieldValues?.[this.field.id]?.value?.length > 0)) {
        this.customFieldValues[this.field.id].value = [];
      } else if (!this.field?.is_multiple_select && !this.customFieldValues?.[this.field.id]?.value) {
        this.customFieldValues[this.field.id].value = null;
      }
    }
  }

  deleteFile(field: CustomFieldsDao, uploadedFile: CustomFieldFileUploadModel, index: number) {
    this.formsService.deleteFile(uploadedFile).subscribe({
      next: (result: { success: boolean }) => {
        if (this.customFieldValues[field.id].file_ids.indexOf(uploadedFile.id) > -1) {
          this.customFieldValues[field.id].file_ids.splice(this.customFieldValues[field.id].file_ids.indexOf(uploadedFile.id), 1);
          if (this.customFieldValues[field.id].file_ids.length === 0) {
            this.formsService.imageUploaded = false;
          }
        }
        field.uploadedFiles.splice(index, 1);
        this.updateCustomFieldValuesInLocalStorage();
      },
      error: (error: HttpErrorResponse) => {
        LoggerService.error(error);
      }
    });
  }

  onFileChanged(evt: any, field: CustomFieldsDao): void {
    this.formsService.imageUploaded = false;
    field.errorMessage = undefined;

    const target = <DataTransfer>evt.target;
    let largeFileSize = false;
    let invalidFileType = false;


    if (Number(field.is_multiple_file) === 1 && target.files.length > 5) {
      field.errorMessage = 'summary_page_translations.maximum_x_files';
      if (this.customFieldValues[this.field.id].file_ids.length === 0) {
        this.customFieldValues[this.field.id].value = '';
      }
      this.waitForFileUpload = false;
      this.formsService.imageUploaded = true;
      return;
    }

    if (Number(field.is_multiple_file) === 1 && field.uploadedFiles && field.uploadedFiles.length > 0) {
      const totalFileLength = target.files.length + field.uploadedFiles.length;
      if (totalFileLength > 5) {
        field.errorMessage = 'summary_page_translations.maximum_x_files';
        if (this.customFieldValues[this.field.id].file_ids.length === 0) {
          this.customFieldValues[this.field.id].value = '';
        }
        this.waitForFileUpload = false;
        this.formsService.imageUploaded = true;
        return;
      }
    }

    for (let index = 0; index < target?.files?.length; index++) {
      const file = target.files[index];

      if (file.size > Number(this.maxFileSize)) {
        field.errorMessage = 'summary_page_translations.max_file_size_5mb';
        if (this.customFieldValues[this.field.id].file_ids.length === 0) {
          this.customFieldValues[this.field.id].value = '';
        }
        this.waitForFileUpload = false;
        this.formsService.imageUploaded = true;
        break;
      }

      if (!largeFileSize && field.customSupportedMimeType?.split(',')?.indexOf(file.type) === -1) {
        invalidFileType = true;
        field.errorMessage = field.supported_mime_type === 'pdf'
          ? 'summary_page_translations.document_invalid_file_type'
          : 'summary_page_translations.image_invalid_file_type';
        if (this.customFieldValues[this.field.id].file_ids.length === 0) {
          this.customFieldValues[this.field.id].value = '';
        }
        this.waitForFileUpload = false;
        break;
      }
    }

    if (invalidFileType || largeFileSize || !target?.files?.length) { return; }
    this.waitForFileUpload = true;

    this.formsService.uploadCustomFieldFiles(target.files, field).subscribe({
      next: result => {
        if ('errors' in result) {
          if (result.errors.code === 'malware') {
            field.errorMessage = 'summary_page_translations.malware_found';
          } else if (result.errors.code === 'file-size') {
            field.errorMessage = 'summary_page_translations.max_file_size_5mb';
          } else {
            field.errorMessage = result.errors.message;
          }

          this.waitForFileUpload = false;
          this.formsService.imageUploaded = false;
          return;
        }

        if (!field.uploadedFiles) {
          field.uploadedFiles = [];
        }

        if (field.uploadedFiles && field.uploadedFiles.length >= 0) {
          this.formsService.imageUploaded = true;
          this.waitForFileUpload = false;
          field.uploadedFiles = field.uploadedFiles.concat(<CustomFieldFileUploadModel[]>result);
        }

        if (!this.customFieldValues[field.id].file_ids) {
          this.customFieldValues[field.id].file_ids = [];
        }

        for (const fileResult of <CustomFieldFileUploadModel[]>result) {
          this.customFieldValues[field.id].file_ids.push(fileResult.id);
        }

        this.updateCustomFieldValuesInLocalStorage();
      },
      error: (error: HttpErrorResponse) => {
        this.formsService.imageUploaded = true;
        this.waitForFileUpload = false;
        LoggerService.error(error);
      }
    });
  }

  getFilesByFileIds(fileIds: number[]) {
    this.formsService.getFilesByFileIds(fileIds).subscribe({
      next: (result: CustomFieldFileUploadModel[]) => {
        if (result?.length > 0) {
          this.field.uploadedFiles = result;
        }
      },
      error: (error: HttpErrorResponse) => {
        console.error(error);
      }
    });
  }

  onChange(event: any, field: CustomFieldsDao) {
    if (field.type === 'select') {
      this.customFieldValues[field.id].type = 'select';
      let oldCustomFieldDependencyIds: number[] = [];

      if (field?.customFieldDependencies?.length > 0) {
        oldCustomFieldDependencyIds = field.customFieldDependencies.map(i => i.id);
      }

      const option = field.custom_field_options.find(option => option.id === event);
      if (option) {
        this.radioButtonChangeEvent.emit({
          customField: this.field,
          customFieldOption: option,
          oldCustomFieldDependencyIds,
          removeOldCustomFieldDependencyIds: true
        });
      }

      this.updateCustomFieldValuesInLocalStorage();
    }
  }

  getCustomFieldOptionById(id: number | string, options: CustomFieldOptions[]): CustomFieldOptions {
    const option = options.find((item: CustomFieldOptions) => {
      return Number(item.id) === Number(id);
    });
    if (option) {
      return option;
    }
    return null;
  }

  updateCustomFieldValuesInLocalStorage() {
    if (this.customFieldValues?.[this.field.id]?.file_ids?.length === 0) {
      this.customFieldValues[this.field.id].value = '';
    }
    this.utilService.updateLocalStorage(this.customFieldValuesLSNameKey, this.customFieldValues);
  }

  onSelectCheckbox(event: any, field: CustomFieldsDao) {
    if (field.child_custom_field_id) {
      if (event.target.checked) {
        this.checkboxChangeEvent.emit({
          customField: field,
          checked: true,
        });
      } else {
        this.checkboxChangeEvent.emit({
          customField: field,
          checked: false,
        });
      }
    }

    if (field.child_event_id) {
      if (event.target.checked) {
        this.customFieldService.toggleCustomFieldChildEvent.emit({
          checked: true,
          childEvent: field.child_event,
          label: field.label
        });
      } else {
        this.customFieldService.toggleCustomFieldChildEvent.emit({ checked: false, childEvent: null, label: null });
      }
    }
    this.updateCustomFieldValuesInLocalStorage();
  }

  onRadioValueChanged(
    item: CustomFieldOptions,
    removeOldCustomFieldDependencyIds: boolean
  ): void {
    let oldCustomFieldDependencyIds: number[] = [];

    if (this.field?.customFieldDependencies?.length > 0) {
      oldCustomFieldDependencyIds = this.field.customFieldDependencies.map(i => i.id);
    }

    this.customFieldValues[this.field.id] = item.id;
    this.field.customFieldDependencies = [];
    this.radioButtonChangeEvent.emit({
      customField: this.field,
      customFieldOption: item,
      oldCustomFieldDependencyIds,
      removeOldCustomFieldDependencyIds
    });

    this.updateCustomFieldValuesInLocalStorage();
  }

  onChangeDate(event: MatDatepickerInputEvent<any>): void {
    if (this.field.format) {
      this.customFieldValues[this.field.id] = moment(this.selectedDate).format(this.field.format.toUpperCase());
    } else {
      this.customFieldValues[this.field.id] = moment(this.selectedDate).format(this.dateFormat);
    }

    this.updateCustomFieldValuesInLocalStorage();

    // Emit date context event when enforcement is enabled for field
    if (this.field.has_date_context_enforcement && this.field.date_context_start && this.field.date_context_end) {
      this.customEventService.updateWidgetDateContext.emit({
        date: moment(this.selectedDate).format(this.dateFormatIso),
        date_context_start: this.field.date_context_start,
        date_context_end: this.field.date_context_end
      });
    }
  }

  onSelectCard(option: CustomFieldOptions, index: number): void {
    if (this.field.is_multiple_select === 1) {
      if (!this.customFieldValues[this.field.id].value) {
        this.customFieldValues[this.field.id].value = [];
      }
      if (this.customFieldValues[this.field.id].value?.length === 0) {
        this.customFieldValues[this.field.id].value = [option.id];
      } else {
        if (this.customFieldValues[this.field.id].value?.indexOf(option.id) === -1) {
          this.customFieldValues[this.field.id].value.push(option.id);
        } else {
          this.customFieldValues[this.field.id].value.splice(this.customFieldValues[this.field.id].value?.indexOf(option.id), 1);
        }
      }
    } else {
      if (this.customFieldValues[this.field.id].value?.indexOf(option.id) === -1) {
        this.customFieldValues[this.field.id].value = [option.id];
      } else {
        this.customFieldValues[this.field.id].value.splice(this.customFieldValues[this.field.id].value?.indexOf(option.id), 1);
      }
    }

    let oldCustomFieldDependencyIds: number[] = [];
    if (this.field?.customFieldDependencies?.length > 0) {
      oldCustomFieldDependencyIds = this.field.customFieldDependencies.map(i => i.id);
    }

    this.radioButtonChangeEvent.emit({
      customField: this.field,
      customFieldOption: option,
      oldCustomFieldDependencyIds,
      removeOldCustomFieldDependencyIds: true
    });

    this.updateCustomFieldValuesInLocalStorage();
  }

  addNumberCardValue(option: CustomFieldOptions): void {
    if (Number(option?.option_value) < option.number_max) {
      option.option_value = Number(option?.option_value) + 1;
      for (const optionValue of this.customFieldValues[this.field.id].option_values) {
        if (optionValue.custom_field_option_id === option.id) {
          optionValue.custom_field_option_value = option.option_value;
        }
      }
      this.updateCustomFieldValuesInLocalStorage();
    }
  }

  reduceNumberCardValue(option: CustomFieldOptions): void {
    if (Number(option?.option_value) > option.number_min) {
      option.option_value = Number(option?.option_value) - 1;
      for (const optionValue of this.customFieldValues[this.field.id].option_values) {
        if (optionValue.custom_field_option_id === option.id) {
          optionValue.custom_field_option_value = option.option_value;
        }
      }
      this.updateCustomFieldValuesInLocalStorage();
    }
  }

  customSearchFn(term: string, item: any) {
    if (this.field?.is_multi_language === 0) {
      term = term.toLocaleLowerCase();
      const customFieldOption: CustomFieldOptions = this.getCustomFieldOptionById(item, this.field?.custom_field_options);
      return customFieldOption?.label?.toLocaleLowerCase()?.indexOf(term) > -1;
    } else {
      term = term.toLocaleLowerCase();
      const customFieldOption: CustomFieldOptions = this.getCustomFieldOptionById(item, this.field?.custom_field_options);
      if (
        customFieldOption._translations
        && customFieldOption._translations[this.lang]
        && customFieldOption._translations[this.lang]?.label) {
        return customFieldOption._translations[this.lang]?.label?.toLocaleLowerCase()?.indexOf(term) > -1;
      } else {
        return customFieldOption?.label?.toLocaleLowerCase()?.indexOf(term) > -1;
      }
    }
  }

  bindYoutubeInfoUrlClickHandler(): void {
    setTimeout(() => {
      this.youtubeInfoUrlDynamicElements = this.elementRef.nativeElement.querySelectorAll('.select-option-youtube-element');
      if (this.youtubeInfoUrlDynamicElements.length > 0) {
        this.youtubeInfoUrlDynamicElements.forEach((element: any) => {
          element.addEventListener('click', () => {
            if (element.dataset?.option) {
              const custom_field_option = JSON.parse(element.dataset.option) as CustomFieldOptions;
              this.openYoutubeVideo(custom_field_option);
            }
          });
        });
      }
    }, 100);
  }

  unbindYoutubeInfoUrlClickHandler(): void {
    if (this.youtubeInfoUrlDynamicElements.length > 0) {
      this.youtubeInfoUrlDynamicElements.forEach((element: any) => {
        element.removeEventListener('click', null);
      });
    }
  }

  openYoutubeVideo(option: CustomFieldOptions): void {
    const matDialogConfig: MatDialogConfig = {
      data: {
        youtubeUrl: option.youtube_url
      },
      panelClass: 'calenso-generic-dialog-box',
      width: '550px',
      position: {
        top: '50px'
      }
    };

    this.matDialog.open(YoutubeModalComponent, matDialogConfig);
  }

  openImagePopup(option: CustomFieldOptions, imageElement: any): void {
    const matDialogConfig: MatDialogConfig = {
      data: {
        imageUrl: option.image
      },
      panelClass: 'calenso-image-popup-dialog-box',
      width: '1020px',
      position: {
        top: '50px'
      }
    };

    if (imageElement?.naturalHeight && imageElement?.naturalWidth) {
      if (imageElement?.naturalWidth <= 1280) {
        matDialogConfig.width = `${imageElement?.naturalWidth}px`;
      }
    }

    this.matDialog.open(ImageModalComponent, matDialogConfig);
  }
}
