import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-cw-title',
  templateUrl: './cw-title.component.html',
  styleUrls: ['./cw-title.component.scss'],
  standalone: true,
})
export class CwTitleComponent {

  @Input() title: string;
  @Input() class: string;
}
