import { Component, Input } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-cw-question-mark',
  templateUrl: './cw-question-mark.component.html',
  styleUrls: ['./cw-question-mark.component.scss'],
  standalone: true,
  imports: [NgbTooltip, FontAwesomeModule],
})
export class CwQuestionMarkComponent {
  @Input() description: string;
  @Input() id: string;
}
