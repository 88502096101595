import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, FormsModule, NgForm } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { APPOINTMENT_CONSTANT } from '../../../constants/appointment-constants';
import { TRANSLATION_TEMPLATES } from '../../../constants/translation-templates-constants';
import { DEFAULT_CUSTOMER_FIELDS, DEFAULT_CUSTOMER_PROFILES_ORDER, DEFAULT_REQUIRED_CUSTOMER_FIELDS, MEETING_TYPE_ID } from '../../../constants/widget-constants';
import { CustomFieldsDao, CustomerDbModel } from '../../../db-models/appointment-custom-fields-dao';
import { StoresDao } from '../../../db-models/stores-dao';
import { CustomBookingMessageTemplate } from '../../../db-models/widget-conf-dao';
import { CartItem, EventCartItem, GuestModel } from '../../../models/cart.model';
import { CustomerProfile, GlobalObjects, Partner } from '../../../models/global';
import { MeetingTypeModel } from '../../../models/meeting-types.model';
import { AppointmentState, ConferenceState, EventState, UserState } from '../../../models/state.model';
import { WidgetColorConf } from '../../../models/widget-color.model';
import { CustomerPrefillDataModel } from '../../../models/widget-conf';
import { CustomEventService } from '../../../services/custom-event.service';
import { FormsService } from '../../../services/forms.service';
import { HelperService } from '../../../services/helper.service';
import { LocalStorageService } from '../../../services/local-storage.service';
import { LoggerService } from '../../../services/logger.service';
import { PartnerService } from '../../../services/partner.service';
import { UtilService } from '../../../services/util.service';
import { WidgetUtilService } from '../../../services/widget-util.service';
import { CalioMeetingTemplatePipe } from '../../../shared/pipes/calio-meeting-template.pipe';
import { TranslationPipe } from '../../../shared/pipes/translation.pipe';
import { AdditionalGuestComponent } from '../additional-guest/additional-guest.component';
import { CustomerFormComponent } from '../customer-form/customer-form.component';
import { CustomfieldsComponent } from '../customfields/customfields.component';
import { CwCardHeaderComponent } from '../theme/cw-card-header/cw-card-header.component';
import { CwNewCardComponent } from '../theme/cw-new-card/cw-new-card.component';

@Component({
  selector: 'app-personal-data-form',
  templateUrl: './personal-data-form.component.html',
  styleUrls: ['./personal-data-form.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  standalone: true,
  imports: [CwNewCardComponent, CwCardHeaderComponent, NgSelectModule, FormsModule, CustomerFormComponent, CustomfieldsComponent, AdditionalGuestComponent, TranslateModule, TranslationPipe, CalioMeetingTemplatePipe]
})
export class PersonalDataFormComponent implements OnInit {

  @Input() userState: UserState;
  @Input() brokerState: UserState;
  @Input() eventState: EventState;
  @Input() globals: GlobalObjects;
  @Input() customFields: CustomFieldsDao[];
  @Input() partner: Partner;
  @Input() selectedJsonCustomFields: {
    [key: number]: { type: string; value: any } | any;
  };
  @Input() customFieldValues: {
    [key: number]: {
      type: string, value: any, option_values: {
        custom_field_option_id: number, custom_field_option_value: string,
      }[]
    } | any
  };
  @Input() isMobile: boolean;
  @Input() type: string;
  @Input() lang: string;
  @Input() hideLoginOnBookingSite: number;
  @Input() courtesyForm: string;
  @Input() appointmentCart?: CartItem[] = [];
  @Input() cart?: EventCartItem[] = [];
  @Input() widgetColorConf: WidgetColorConf;
  @Input() customFieldValuesLSNameKey: string;
  @Input() userDataLSNameKey: string;
  @Input() appointmentState: AppointmentState;
  @Input() selectedJsonCustomerDetail: CustomerPrefillDataModel;
  @Input() meeting_type_id: string;
  @Input() customer_notification_preference: string;
  @Input() widgetTemplates: CustomBookingMessageTemplate[] = [];
  @Input() stores: StoresDao[] = [];
  @Input() conferenceState: ConferenceState

  @Output() updateUserStateEvent = new EventEmitter<UserState>();
  @Output() updateCustomerSelectedEvent = new EventEmitter<any>();
  @Output() updateAdditionalGuestsEvent = new EventEmitter<GuestModel[]>();

  readonly appointmentConstant = APPOINTMENT_CONSTANT;
  readonly meetingTypeId = MEETING_TYPE_ID;
  readonly templateContent = TRANSLATION_TEMPLATES;

  environment: any;
  showPersonalDataForm = false;
  NOTIFICATION_TYPES: any[] = [];
  meetingTypes: MeetingTypeModel[];
  isCustomerLoading = false;
  options: { id: number, text: string }[] = [];
  searchCustomers: EventEmitter<string> = new EventEmitter<string>();
  customers: CustomerDbModel[] = [];
  customerSelected: any;
  selectedCustomerId: number;
  defaultCustomerFields: string[];
  defaultRequiredCustomerFields: string[];
  defaultCustomerProfilesOrder: string[];
  showBrokerCustomerForm = false;
  brokerCustomerFields: string[];
  brokerRequiredCustomerFields: string[];
  selectedCustomerProfile: CustomerProfile;
  customerProfile: CustomerProfile;
  brokerCustomerProfile: CustomerProfile;
  showAdditionalGuestsBlock = false;
  additionalGuestsBlockTotalCapacity: number;

  constructor(
    public translate: TranslateService,
    public partnerService: PartnerService,
    private helperService: HelperService,
    private formService: FormsService,
    private customEventService: CustomEventService,
    private utilService: UtilService,
    private localStorageService: LocalStorageService,
    private widgetUtilService: WidgetUtilService
  ) {
    this.environment = environment;
    this.translate.onLangChange.subscribe(language => this.lang = language.lang);
  }

  ngOnInit() {
    this.widgetUtilService.zipCode && (this.userState.zip = this.widgetUtilService.zipCode);

    if (
      this.partner.is_customer_profiles_feature_enabled === 1 &&
      this.type === APPOINTMENT_CONSTANT.APPOINTMENT
    ) {
      for (const cp of this.partner.customer_profiles) {
        if (cp?.context?.broker?.length > 0) {
          // this.brokerCustomerProfile = cp;
        } else {
          this.customerProfile = cp;
        }
      }

      const foundedCustomerProfile = this.partner.customer_profiles.find(
        profile => profile.id === this.appointmentState.customerProfileId
      );

      if (foundedCustomerProfile) {
        this.selectedCustomerProfile = foundedCustomerProfile;
        this.defaultCustomerFields = this.selectedCustomerProfile?.context?.customer;
        // TODO in future if we wanted to configure mandatory fields from context then uncomment this code and remove default code
        // this.defaultRequiredCustomerFields = this.selectedCustomerProfile?.context?.customer_mandatory;
        this.defaultRequiredCustomerFields = DEFAULT_REQUIRED_CUSTOMER_FIELDS;
        if (this.selectedCustomerProfile?.context?.broker?.length > 0) {
          this.showBrokerCustomerForm = true;
          this.brokerCustomerProfile = this.selectedCustomerProfile;
          this.brokerCustomerFields = this.selectedCustomerProfile?.context?.broker;
          this.brokerRequiredCustomerFields = this.selectedCustomerProfile?.context?.broker_mandatory;
        } else {
          this.brokerState = {};
        }

        if (this.selectedCustomerProfile?.context?.order?.length > 0) {
          this.defaultCustomerProfilesOrder = this.selectedCustomerProfile?.context?.order;
        } else {
          this.defaultCustomerProfilesOrder = DEFAULT_CUSTOMER_PROFILES_ORDER;
        }
      } else {
        LoggerService.warn('[Debug] Customer profile not found and Customer profile id ', this.appointmentState.customerProfileId);
      }
    } else {
      this.defaultCustomerFields = DEFAULT_CUSTOMER_FIELDS;
      this.defaultRequiredCustomerFields = DEFAULT_REQUIRED_CUSTOMER_FIELDS;
      this.defaultCustomerProfilesOrder = DEFAULT_CUSTOMER_PROFILES_ORDER;
    }

    this.customers = this.globals?.customers;
    this.type === APPOINTMENT_CONSTANT.APPOINTMENT ? this.getAppointmentMeetingTypes() : this.getEventMeetingTypes();

    this.setupNotificationType();
    this.renderCustomFields();
    this.setupInitialUserData();

    this.globals?.isInternal && this.internalCustomerSetup();

    this.showAdditionalGuestsBlock = this.displayAdditionalGuestBlock();
  }

  updateUserState(newUserState: UserState): void {
    if (newUserState.customerUuid) {
      this.customEventService.customerChangedInInternalWidgetEvent.emit({ customerUuid: newUserState.customerUuid });
    }
    this.updateUserStateEvent.emit(newUserState);
  }

  updateCustomerSelected(newCustomerSelected: any): void {
    this.updateCustomerSelectedEvent.emit(newCustomerSelected);
  }

  updateAdditionalGuests(additionalGuests: GuestModel[]): void {
    this.updateAdditionalGuestsEvent.emit(additionalGuests);
  }

  renderCustomFields(): void {
    this.partnerService.customFieldsBefore = [];
    this.partnerService.customFieldsAfter = [];
    const customFieldsBefore: CustomFieldsDao[] = [];
    const customFieldsAfter: CustomFieldsDao[] = [];
    this.customFields.forEach(customField => {
      if (customField.position > 0) {
        customFieldsAfter.push(customField);
      } else {
        customFieldsBefore.push(customField);
      }
    });

    this.partnerService.customFieldsBefore = this.helperService.sortByNumber(customFieldsBefore, 'position');
    this.partnerService.customFieldsAfter = this.helperService.sortByNumber(customFieldsAfter, 'position');
  }

  getAppointmentMeetingTypes(): void {
    let finalWorkerId: number;
    let serviceIds: number[] = [];
    if (this.appointmentCart && this.appointmentCart.length > 0) {
      let workerIds: number[] = this.appointmentCart.map((c: CartItem) => c.workerId);
      for (const cart of this.appointmentCart) {
        serviceIds = serviceIds.concat(cart.servicesIds);
      }
      workerIds = [...new Set(workerIds)];
      serviceIds = [...new Set(serviceIds)];
      if (workerIds.length === 1) {
        finalWorkerId = workerIds[0];
      }
    }

    this.getMeetingTypesByAppointmentServiceIds(
      this.conferenceState?.uuid && this.conferenceState?.worker_id ? this.conferenceState.worker_id : finalWorkerId,
      serviceIds,
      this.conferenceState?.uuid || undefined
    );
  }

  getMeetingTypesByAppointmentServiceIds(finalWorkerId: number, serviceIds: number[], conferenceUuid?: string): void {
    this.formService.getMeetingTypesByAppointmentServiceIds(finalWorkerId, serviceIds, conferenceUuid).subscribe({
      next: meetingTypes => {
        if (meetingTypes.length) {
          meetingTypes?.forEach(meetingType => meetingType.is_multi_language = 1);
        }
        this.meetingTypes = meetingTypes ? meetingTypes : [];

        this.setupMeetingType();
      },
      error: (error: HttpErrorResponse) => LoggerService.error(error)
    });
  }

  getEventMeetingTypes(): void {
    if (this.cart && this.cart.length > 0) {
      let eventIds = this.cart.map(cartItem => cartItem.eventId);
      eventIds = [...new Set(eventIds)];
      eventIds?.length && this.getMeetingTypesBySlotWorkerId(eventIds[0]);
    }
  }

  getMeetingTypesBySlotWorkerId(eventId: string | number): void {
    this.formService.getMeetingTypesBySlotWorkerId(eventId).subscribe({
      next: meetingTypes => {
        if (meetingTypes?.length) {
          meetingTypes?.forEach(meetingType => meetingType.is_multi_language = 1);
        }
        this.meetingTypes = meetingTypes ? meetingTypes : [];

        this.setupMeetingType();
      },
      error: (error: HttpErrorResponse) => LoggerService.error(error)
    });
  }

  setupMeetingType(): void {
    if (this.meetingTypes?.length) {
      if (!this.userState.meeting_type_id) {
        this.initializeMeetingType();
        if (!this.userState.meeting_type_id) {
          const defaultMeetingProvider = this.meetingTypes.find(meeting => meeting.is_default === 1);
          this.userState.meeting_type_id = defaultMeetingProvider ? defaultMeetingProvider.id : this.meetingTypes[0].id;
          this.userState.meetingType = defaultMeetingProvider ? defaultMeetingProvider : this.meetingTypes[0];
        }
      }
    } else {
      !this.userState.meeting_type_id && (this.userState.meeting_type_id = this.meetingTypeId.STORE);
    }
  }

  displayAdditionalGuestBlock(): boolean {
    if (
      this.eventState?.additional_guests_enabled === 1 &&
      this.eventState?.number_allowed_additional_guests >= 1
    ) {
      if (!this.cart?.length) {
        return false;
      }

      if (this.cart.length === 1) {
        if (this.cart[0].available_regular_capacity) {
          this.additionalGuestsBlockTotalCapacity = Math.min(this.cart[0].available_regular_capacity - 1, this.eventState.number_allowed_additional_guests);
          // false -> case when available capacity 1 and waiting list not enabled then do not allow to add guests
          // true -> case when available capacity more than 1 and waiting list not enabled then allow to add guests
          return this.cart[0].available_regular_capacity === 1 ? false : true;
        } else if (this.cart[0].is_waitinglist_enabled) {
          this.additionalGuestsBlockTotalCapacity = Math.min(this.cart[0].available_waitinglist_capacity - 1, this.eventState.number_allowed_additional_guests)
          // false -> case when waitinglist capacity 1 and regular capacity is full then do not allow to add guests
          // true -> case when waitinglist capacity more than 1 and regular capacity is full then allow to add guests
          return this.cart[0].available_waitinglist_capacity === 1 ? false : true;
        }

        return false;
      } else {
        // get minimum number of available regular capacity
        let regularCapacityArray = this.cart.map(cartItem => cartItem.available_regular_capacity).sort();
        let minRegularCapacity = regularCapacityArray[0] || 0;

        // if minimum number of available regular capacity is > 0 then based on minumum capacity counts block will be shown
        if (minRegularCapacity) {
          this.additionalGuestsBlockTotalCapacity = minRegularCapacity - 1;
          // false -> case when regualr booking capacity is 1 then block should not be shown
          // true -> case when regualr booking capacity is > 1 then block will be shown
          return minRegularCapacity > 1 ? true : false;
        } else {
          // get minimum number of available waiting list capacity
          // waiting list enable and regular booking is full then and then check for waiting list capacity otherwise 0 will be passed default
          const minimumWaitingListCapacityArray = this.cart.map(cartItem =>
            cartItem.is_waitinglist_enabled && cartItem.available_regular_capacity === 0
              ? (cartItem.available_waitinglist_capacity)
              : 0
          ).sort();
          let minimumWaitingListCapacity = minimumWaitingListCapacityArray[0] || 0;
          this.additionalGuestsBlockTotalCapacity = minimumWaitingListCapacity;
          // if minimum number of available waiting list capacity is > 0 then based on minumum capacity counts block will be shown
          if (minimumWaitingListCapacity) {
            this.additionalGuestsBlockTotalCapacity = minimumWaitingListCapacity - 1;
            // false -> case when waiting list booking capacity is 1 then block should not be shown
            // true -> case when waiting list booking capacity is > 1 then block will be shown
            return minimumWaitingListCapacity > 1 ? true : false;
          }
        }

        return false;
      }
    } else {
      return false;
    }
  }

  onTogglePersonalForm(event: boolean): void {
    this.showPersonalDataForm = !event;
  }

  internalCustomerSetup(): void {
    this.options = this.getCustomersDropdownOptions();

    this.searchCustomers.pipe(
      distinctUntilChanged(),
      debounceTime(300),
      switchMap((term: string) => {
        this.isCustomerLoading = true;
        if (term && term.trim() !== '') {
          return this.partnerService.searchCustomersByTag(term);
        } else {
          return this.partnerService.getCustomers();
        }
        // this.dataService.getPeople(term)
      })
    ).subscribe({
      next: customers => {
        if (customers) {
          this.customers = customers;
          this.options = this.getCustomersDropdownOptions();
        } else {
          this.customers = [];
          this.options = [];
        }
        this.isCustomerLoading = false;
      },
      error: (error: HttpErrorResponse) => LoggerService.error(error)
    });
  }

  getCustomersDropdownOptions(): { id: number, text: string }[] {
    let options: { id: number, text: string }[] = [];
    // let personalName: string;
    let text: string;
    if (this.customers && this.customers.length === 0) {
      return [];
    }
    for (const i in this.customers) {
      if (this.customers[i]) {
        text = '';
        if (this.customers[i]['company_name']) {
          text = text + this.customers[i]['company_name'];
        }
        if (this.customers[i]['email']) {
          text = text + ' (' + this.customers[i]['email'] + ')';
        }
        if (this.customers[i] && this.customers[i]['id']) {
          options.push({
            id: this.customers[i]['id'],
            text: text
          });
        }
      }
    }
    // sort array
    options = this.utilService.multiSort(options, ['text'], ['asc']);
    return options;
  }

  onCustomerSelected(id: number): void {
    this.customerSelected = this.customers.find(customer => Number(customer.id) === Number(this.selectedCustomerId));
    this.userState.forename = this.customerSelected?.prename;
    this.userState.gender = this.customerSelected?.gender;
    this.userState.lastName = this.customerSelected?.lastname;
    this.userState.eMail = this.customerSelected?.email;
    this.userState.phone = this.customerSelected?.phone;
    this.userState.mobile = this.customerSelected?.mobile;
    this.userState.customerId = this.customerSelected?.id;
    this.userState.customerUuid = this.customerSelected?.uuid;
    // this.userState.createAccount = false;
    this.userState.street = this.customerSelected?.street;
    this.userState.zip = this.customerSelected?.zip;
    this.userState.city = this.customerSelected?.city;

    // Set user state
    this.updateUserState(this.userState);
    this.updateCustomerSelected(this.customerSelected);
  }

  clearUserData(): void {
    this.userState.forename = undefined;
    this.userState.lastName = undefined;
    this.userState.gender = undefined;
    this.userState.eMail = undefined;
    this.userState.customerId = undefined;
    this.userState.customerUuid = undefined;
    this.userState.phone = undefined;
    this.userState.mobile = undefined;
    this.userState.street = undefined;
    this.userState.zip = undefined;
    this.userState.city = undefined;
    this.userState.comment = undefined;
    this.customerSelected = undefined;
    this.selectedCustomerId = undefined;
    this.userState.meeting_type_id = undefined;
    this.userState.meetingType = undefined;

    this.setupMeetingType();
    this.setupNotificationType();
    this.resetCustomFieldvalues();
    this.updateCustomerSelected(undefined);
    this.updateUserState(this.userState);
  }

  setupNotificationType(): void {
    if (this.partner?.widget_email_shown !== 1) {
      this.NOTIFICATION_TYPES = [{
        display: 'sms',
        value: 'sms'
      }];
      if (this.partner && Number(this.partner.ask_for_customer_notification_preference) === 1) {
        this.initializeCustomerNotificationPreference();
        if (!this.userState.customer_notification_preference) {
          this.userState.customer_notification_preference = 'sms';
        }
      }
    } else {
      this.NOTIFICATION_TYPES = [{
        display: 'email',
        value: 'email'
      }, {
        display: 'sms',
        value: 'sms'
      }, {
        display: 'both_sms_email',
        value: 'both'
      }];
      if (this.partner && Number(this.partner.ask_for_customer_notification_preference) === 1) {
        this.initializeCustomerNotificationPreference();
        if (!this.userState.customer_notification_preference) {
          this.userState.customer_notification_preference = 'email';
        }
      }
    }
  }

  resetCustomFieldvalues(): void {
    const fieldIds = Object.keys(this.customFieldValues);
    for (const id of fieldIds) {
      if (this.customFieldValues[id]?.type === 'select') {
        if (Array.isArray(this.customFieldValues[id].value)) {
          this.customFieldValues[id].value = [];
        } else {
          this.customFieldValues[id].value = undefined;
        }
      } else if (this.customFieldValues[id].type === 'file') {
        this.customFieldValues[id].file_ids = [];
      } else {
        this.customFieldValues[id] = undefined;
      }
    }

    this.localStorageService.removeItem(this.customFieldValuesLSNameKey, () => void 0);
  }

  setupInitialUserData(): void {
    if (this.selectedJsonCustomerDetail) {
      if (this.selectedJsonCustomerDetail?.id) {
        this.getCustomerDataByUuid(this.selectedJsonCustomerDetail.id);
      } else {
        this.selectedJsonCustomerDetail?.title && (this.userState.title = Number(this.selectedJsonCustomerDetail.title));
        this.userState.forename = this.selectedJsonCustomerDetail.prename;
        this.userState.lastName = this.selectedJsonCustomerDetail.lastname;
        this.userState.gender = this.selectedJsonCustomerDetail.gender;
        this.userState.eMail = this.selectedJsonCustomerDetail.email;

        if (
          this.selectedJsonCustomerDetail.phone?.trim() &&
          !this.selectedJsonCustomerDetail.phone.includes('+')
        ) {
          this.selectedJsonCustomerDetail.phone = "+" + this.selectedJsonCustomerDetail.phone.trim();
        }
        this.userState.phone = this.selectedJsonCustomerDetail.phone;

        if (
          this.selectedJsonCustomerDetail.mobile?.trim() &&
          !this.selectedJsonCustomerDetail.mobile.includes('+')
        ) {
          this.selectedJsonCustomerDetail.mobile = "+" + this.selectedJsonCustomerDetail.mobile.trim();
        }
        this.userState.mobile = this.selectedJsonCustomerDetail.mobile;

        this.userState.company = this.selectedJsonCustomerDetail.company;
        this.userState.customerId = undefined;
        this.userState.customerUuid = undefined;
        this.userState.street = this.selectedJsonCustomerDetail.street;
        this.userState.zip = this.selectedJsonCustomerDetail.zip;
        this.userState.city = this.selectedJsonCustomerDetail.city;
        this.selectedJsonCustomerDetail?.country && (this.userState.country_id = Number(this.selectedJsonCustomerDetail.country));
        this.userState.comment = this.selectedJsonCustomerDetail.comment;
        this.selectedCustomerId = undefined;
        this.customerSelected = undefined;
        this.initializeMeetingType();
        this.initializeCustomerNotificationPreference();
        this.updateCustomerSelected(this.customerSelected);
        this.updateUserState(this.userState);
      }
    }
  }

  initializeCustomerNotificationPreference(): void {
    if (this.customer_notification_preference) {
      this.userState.customer_notification_preference = this.customer_notification_preference;
    }
  }

  initializeMeetingType(): void {
    if (this.meeting_type_id && this.meetingTypes?.length > 0) {
      const foundedMeeting: MeetingTypeModel = this.meetingTypes.find((item: MeetingTypeModel) => {
        return item.id === Number(this.meeting_type_id);
      });
      if (foundedMeeting) {
        this.userState.meeting_type_id = Number(this.meeting_type_id);
        this.userState.meetingType = foundedMeeting;
      } else {
        this.userState.meeting_type_id = undefined;
        this.userState.meetingType = null;
      }
    }
  }

  getCustomerDataByUuid(uuid: string): void {
    this.partnerService.getCustomersByUuid(uuid).subscribe({
      next: (result: { customer: CustomerDbModel }) => {
        if (result?.customer) {
          this.customerSelected = result.customer;
          this.customers = [Object.assign({}, result.customer)];
          this.options = this.getCustomersDropdownOptions();
          this.selectedCustomerId = result.customer.id;

          this.userState.forename = this.customerSelected?.prename;
          this.userState.lastName = this.customerSelected?.lastname;
          this.userState.gender = this.customerSelected?.gender;
          this.userState.eMail = this.customerSelected?.email;
          this.customerSelected?.phone && (this.userState.phone = this.customerSelected?.phone);
          this.customerSelected?.mobile && (this.userState.mobile = this.customerSelected?.mobile);
          this.userState.customerId = this.customerSelected?.id;
          this.userState.customerUuid = this.customerSelected?.uuid;
          this.userState.street = this.customerSelected?.street;
          this.userState.zip = this.customerSelected?.zip;
          this.userState.city = this.customerSelected?.city;

          this.initializeMeetingType();
          this.initializeCustomerNotificationPreference();
          this.updateCustomerSelected(this.customerSelected);
          this.updateUserState(this.userState);
        }
      },
      error: (error: HttpErrorResponse) => LoggerService.error(error)
    });
  }
}
