<div
  class="custom-control d-flex {{ containerClass }}"
  [ngClass]="{
    'custom-checkbox': isCheckbox, 'custom-radio': !isCheckbox,
    'cbw-mobile-section': isMobile
  }"
>
  <input
    (change)="onChange($event, customId)"
    [checked]="checked"
    [disabled]="isDisabled"
    [value]="value"
    class="custom-control-input cbw-checkbox-radio-button css-checkbox"
    id="{{ customId }}"
    name="{{ name }}"
    type="{{ isCheckbox ? 'checkbox' : 'radio' }}"
    attr.role="{{ isCheckbox ? 'checkbox' : 'radio' }}"
    attr.aria-checked="{{ checked }}"
    attr.aria-labelledby="label_{{ customId }}"
  >
  <label
    id="label_{{ customId }}"
    for="{{ customId }}"
    class="custom-control-label cbw-checkbox-radio-button-label css-label d-flex {{ labelClass }}"
    [ngClass]="isMobile ? 'cbw-mobile-section' : 'cbw-desktop-section'"
  >
    <ng-content />
  </label>
</div>
