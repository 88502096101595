<div class="qualification-questions-wrapper cbw-qualification-questions-wrapper">
  @if (supportedWidgetLanguages?.length > 1 && langSwitcher) {
    <div ngbDropdown class="lang-dropdown-wrapper cbcw-lang-dropdown-wrapper">
      <button class="nav-link lang-dropdown" id="qualification-lang-dropdown" ngbDropdownToggle>
        <span>
          @for (language of languages; track language) {
            @if (lang === language.locale) {
              <img width="20px" height="20px" (click)="switchLanguage(language.locale)" alt="{{ language.text }}" src="{{ deployUrl + language.flagIcon }}" />
            }
          }
        </span>
      </button>

      @if (supportedWidgetLanguages?.length > 1) {
        <div class="lang-dropdown-menu" ngbDropdownMenu aria-labelledby="qualification-lang-dropdown">
          @for (language of languages; track language) {
            @if (lang !== language.locale && supportedWidgetLanguages && supportedWidgetLanguages.indexOf(language.value) > -1) {
              <a ngbDropdownItem class="lang-dropdown-menu-item" href="javascript:void(0)" (click)="switchLanguage(language.locale)">
                <span class="d-flex justify-content-center">
                  <img width="20px" height="20px" alt="{{ language.text }}" src="{{ deployUrl + language.flagIcon }}" />
                </span>
              </a>
            }
          }
        </div>
      }
    </div>
  }

  <div class="qualification-questions cbw-qualification-questions">
    @if (!widgetQualificationBasedQuestionsTitle && !widgetQualificationBasedQuestionsDescription) {
      <div class="skeleton-animation d-flex flex-column align-items-center">
        <div class="bar w-50 mb-2" style="height: 20px;"></div>
        <div class="bar w-100" style="height: 20px;"></div>
      </div>
    }

    @if (widgetQualificationBasedQuestionsTitle && widgetQualificationBasedQuestionsDescription) {
      <div class="heading">
        <h1>{{ widgetQualificationBasedQuestionsTitle | translation: templateContent.TEMPLATE_CONTENT: lang }}</h1>
        <p>{{ widgetQualificationBasedQuestionsDescription | translation: templateContent.TEMPLATE_CONTENT: lang }}</p>
      </div>
    }

    <form id="qualification_question" name="qualification_question" #qualificationQuestion="ngForm">
      <app-customfields
        beforeCustomField
        [(userState)]="userState"
        [(appointmentState)]="appointmentState"
        [globals]="globals"
        [customFields]="qualificationQuestions"
        [partner]="partner"
        [(customFieldValues)]="qualificationQuestionsValues"
        [customFieldValuesLSNameKey]="qualificationQuestionLSNameKey"
        [type]="appointmentConstant.APPOINTMENT"
        [renderBefore]="true"
        [lang]="lang"
      />

      <div class="action-buttons">
        <calenso-button
          id="cbw-skip-qualification-question"
          customCss="cbw-skip-qualification-question"
          [disabled]="qualificationQuestion.invalid"
          [widgetColorConf]="widgetColorConf"
          [text]="widgetQualificationBasedQuestionsSkipButton | translation: templateContent.TEMPLATE_CONTENT: lang"
          (clickEvent)="skipQualificaionQuestion(qualificationQuestion.invalid)"
        />

        <calenso-button
          id="cbw-submit-qualification-question"
          customCss="cbw-submit-qualification-question"
          class="ms-2"
          placement="right"
          [widgetColorConf]="widgetColorConf"
          [text]="widgetQualificationBasedQuestionsNextButton | translation: templateContent.TEMPLATE_CONTENT: lang"
          (clickEvent)="submitQualificaionQuestion(qualificationQuestion.invalid)"
        />
      </div>
    </form>
  </div>
</div>
