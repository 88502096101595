import { PartnerDao } from '../db-models/widget-conf-dao';
import { Subscription } from './global';
import { LanguageDao } from '../db-models/language.model';
import { CountryDbModel } from './country.model';

export class WidgetConf {
  id: number;
  uuid: string;
  name: string;
  bookingName: string;
  logo: string;
  currency: string;
  url: string;
  // Need to check the type boolean or number
  isBusiness: number;
  headerBgColor: string;
  footerBgColor: string;
  textColor: string;
  footerBtnColor: string;
  headerActiveColor: string;
  // Need to check the type boolean or number
  appointmentBookingEnabled: boolean;
  // Need to check the type boolean or number
  eventBookingEnabled: boolean;
  // Need to check the type boolean or number
  resourceBookingEnabled: boolean;
  token: string;
  languageCode: string;
  ical_hash: string;
  backgroundColor: string;
  // Need to check the type boolean or number
  showEgalWer: boolean;
  countryCode: string;
  // Need to check the type boolean or number
  needCustomerAddress: boolean;
  needPhoneNumber: boolean;
  needMobileNumber: boolean;
  needMessage: boolean;
  cartSupported: boolean;
  courtesyForm: string;
  appointmentLeadTime: number;
  eventLeadTime: number;
  appointmentBookingInfo: string;
  eventSlotBookingInfo: string;
  whitelabel?: boolean;
  subscriptionTypeId: number;
  hide_login_on_bookingsite: number;
  hide_back_on_bookingsite: number;
  hide_book_additional_appointment_button: number;
  allow_multiple_services_bookings: number;
  hide_widget_cart_functionality_message: number;
  supported_widget_languages: string[];
  subscription: Subscription;
  show_stores_as_dropdown: number;
  hide_event_capacity: number;
  scroll_to_element_feature_is_enabled: number;
  has_valid_coupons: number;
  is_widget_postcode_worker_random_selection_enabled: number;
  language: LanguageDao
  language_id: number;
  language_identifier: string;
  show_widget_slot_location: number;
  widget_jumps_to_resources_step_when_single_service: number;
  is_appointment_reservation_enabled: number;
  split_personal_form_and_summary_in_widget: number;
  show_cross_selling_services_on_summary_step: number;

  public static createWidgetConfObject(partnerDao: PartnerDao): WidgetConf {
    const widgetConf = new WidgetConf();
    widgetConf.id = partnerDao.id;
    widgetConf.uuid = partnerDao.uuid;
    widgetConf.name = partnerDao.name;
    widgetConf.bookingName = partnerDao.booking_name;
    widgetConf.logo = partnerDao.logo;
    widgetConf.currency = partnerDao.currency;
    widgetConf.url = partnerDao.url;
    widgetConf.isBusiness = partnerDao.is_business;
    widgetConf.headerBgColor = partnerDao.widget_header_background_color;
    widgetConf.footerBgColor = partnerDao.widget_footer_background_color;
    widgetConf.textColor = partnerDao.widget_text_color;
    widgetConf.footerBtnColor = partnerDao.widget_footer_button_color;
    widgetConf.headerActiveColor = partnerDao.widget_header_active_color;
    widgetConf.appointmentBookingEnabled = partnerDao.appointment_booking_enabled === 0 ? false : true;
    widgetConf.eventBookingEnabled = partnerDao.event_booking_enabled === 0 ? false : true;
    widgetConf.resourceBookingEnabled = partnerDao.resource_booking_enabled === 0 ? false : true;
    widgetConf.token = partnerDao.token;
    widgetConf.languageCode = partnerDao.language.identifier;
    widgetConf.ical_hash = partnerDao.ical_hash;
    widgetConf.backgroundColor = partnerDao.widget_background_color;
    widgetConf.showEgalWer = partnerDao.egal_wer_show === 0 ? false : true;
    widgetConf.countryCode = partnerDao.country.identifier;
    widgetConf.needCustomerAddress = partnerDao.show_address_on_widget === 0 ? false : true;
    widgetConf.needPhoneNumber = partnerDao.show_phone_on_widget === 0 ? false : true;
    widgetConf.needMobileNumber = partnerDao.show_mobile_on_widget === 0 ? false : true;
    widgetConf.needMessage = partnerDao.show_message_on_widget === 0 ? false : true;
    widgetConf.cartSupported = partnerDao.is_cart_supported === 0 ? false : true;
    widgetConf.courtesyForm = partnerDao.courtesy_form;
    widgetConf.appointmentLeadTime = partnerDao.appointment_lead_time;
    widgetConf.eventLeadTime = partnerDao.event_lead_time;
    widgetConf.appointmentBookingInfo = partnerDao.appointment_booking_info;
    widgetConf.eventSlotBookingInfo = partnerDao.event_slot_booking_info;
    widgetConf.subscriptionTypeId = partnerDao.subscription.subscription_type_id;
    widgetConf.hide_login_on_bookingsite = partnerDao.hide_login_on_bookingsite;
    widgetConf.hide_back_on_bookingsite = partnerDao.hide_back_on_bookingsite;
    widgetConf.hide_book_additional_appointment_button = partnerDao.hide_book_additional_appointment_button;
    widgetConf.allow_multiple_services_bookings = partnerDao.allow_multiple_services_bookings;
    widgetConf.hide_widget_cart_functionality_message = partnerDao.hide_widget_cart_functionality_message;
    widgetConf.supported_widget_languages = partnerDao.supported_widget_languages;
    widgetConf.subscription = partnerDao.subscription;
    widgetConf.show_stores_as_dropdown = Number(partnerDao.show_stores_as_dropdown);
    widgetConf.hide_event_capacity = Number(partnerDao.hide_event_capacity);
    widgetConf.scroll_to_element_feature_is_enabled = Number(partnerDao.scroll_to_element_feature_is_enabled);
    widgetConf.has_valid_coupons = partnerDao.has_valid_coupons;
    widgetConf.is_widget_postcode_worker_random_selection_enabled = Number(partnerDao.is_widget_postcode_worker_random_selection_enabled);
    widgetConf.language = partnerDao.language;
    widgetConf.language_id = Number(partnerDao.language_id);
    widgetConf.language_identifier = partnerDao.language_identifier;
    widgetConf.show_widget_slot_location = partnerDao.show_widget_slot_location;
    widgetConf.widget_jumps_to_resources_step_when_single_service = partnerDao.widget_jumps_to_resources_step_when_single_service;
    widgetConf.is_appointment_reservation_enabled = partnerDao.is_appointment_reservation_enabled;
    widgetConf.split_personal_form_and_summary_in_widget = partnerDao.split_personal_form_and_summary_in_widget;
    widgetConf.show_cross_selling_services_on_summary_step = partnerDao.show_cross_selling_services_on_summary_step;

    return widgetConf;
  }
}

export class CssOverwrites {
  id: number;
  uuid: string;
  name: string;
  code: string;
  type: string;
  is_active: number;
  partner_id: number;
  errors?: any;
}

export class CustomerPrefillDataModel {
  title: string;
  prename: string;
  lastname: string;
  email: string;
  mobile: string;
  phone: string;
  id: string;
  street: string;
  gender: string;
  zip: string;
  city: string;
  country_id: number;
  country: CountryDbModel;
  comment: string;
  company: string;
}

export enum CUSTOM_CSS_WIDGET_TYPE {
  widget = 'widget',
  smart_widget = 'smart_widget',
  compact_widget = 'compact_widget'
}
