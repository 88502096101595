@if (!successfulBooking) {
  <div>
    <div class="row justify-content-md-center cb-error-layout">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <app-alert
          [title]="errTitleOut | translate"
          [text]="bookingErrorMessage ? bookingErrorMessage : errMsgOut"
          type="danger"
          [dismissableButton]="false"
        />
      </div>
    </div>
  </div>
}

@if (successfulBooking) {
  <div class="mt-5">
    <div class="d-flex flex-column align-items-center">
      <div 
        class="cbw-final-page-title mt-4"
        [innerHtml]="successTitleTemplate | translation: templateContent.TEMPLATE_CONTENT: lang | trustHtml"
      ></div>
      @if (successFullBookings?.length !== 0) {
        @if (successFullBookings?.length === 1) {
          @for (booking of successFullBookings; track booking) {
            <ng-container *ngTemplateOutlet="singleBookingTemplate; context:{ booking: booking }" />
          }
        }
        @if (successFullBookings?.length > 1) {
          <ng-container *ngTemplateOutlet="multipleBookingTemplate; context:{ bookings: successFullBookings }" />
        }
      }
      @if (successFullBookings?.length === 0 && bookingLimitReached) {
        <ng-container *ngTemplateOutlet="limitReachedTemplate" />
      }
    </div>
  </div>
}

<ng-template #singleBookingTemplate let-booking="booking">
  @if (!bookingLimitReached) {
    <div
      class="cbw-final-page-message mt-4 px-3 text-center"
      [innerHtml]="(successMsgOut | replaceTemplatePlaceholder: booking?.selectedStore) | trustHtml"
    ></div>
  }
  @if (bookingLimitReached) {
    <div
      class="cbw-final-page-message mt-4 px-3 text-center"
      [innerHtml]="widgetSuccessPageEventBookingLimitReachedTemplate | translation: templateContent.TEMPLATE_CONTENT: lang | trustHtml"
    ></div>
  }

  @if (booking?.landingPageLink) {
    <div class="cbw-final-page-link mt-4 px-3 text-center">
      <div>
        <a href="{{ booking?.landingPageLink }}" target="_blank">
          <span>
            {{ manageAppointmentNowTemplate | translation: templateContent.TEMPLATE_CONTENT: lang }}
          </span>
        </a>
      </div>
      <div class="mt-2">
        <a href="javascript:void(0)" (click)="downloadBookingDetail(booking?.bookingUUID)">
          <span class="d-flex align-items-center justify-content-center">
            <span class="pe-1">
              <fa-icon class="icon" icon="download"></fa-icon>
            </span>
            <span>{{ downloadBookingDetailTemplate | translation: templateContent.TEMPLATE_CONTENT: lang }}</span>
          </span>
        </a>
      </div>
    </div>
  }
</ng-template>

<ng-template #multipleBookingTemplate let-bookings="bookings">
  @if (!bookingLimitReached) {
    <div
      class="cbw-final-page-message mt-4 px-3 text-center"
      [innerHtml]="(successMsgOut | replaceTemplatePlaceholder: bookings[0]?.selectedStore) | trustHtml"
    ></div>
  }
  @if (bookingLimitReached) {
    <div class="cbw-final-page-message mt-4 px-3 text-center"
      [innerHtml]="widgetSuccessPageEventBookingLimitReachedTemplate | translation: templateContent.TEMPLATE_CONTENT: lang | trustHtml">
    </div>
  }

  <div class="cbw-final-page-link mt-4 px-3 text-center">
    <div class="d-flex justify-content-center flex-wrap">
      <span>
        {{ manageAppointmentNowTemplate | translation: templateContent.TEMPLATE_CONTENT: lang }}:
      </span>

      @for (booking of bookings; track booking; let index = $index; let last = $last) {
        <a
          class="ps-1"
          href="{{ booking?.landingPageLink }}" target="_blank">
          {{ ('appointments.step3.appointment' | translate) + ' ' + (index + 1) + (last ? '' : ', ') }}
        </a>
      }
    </div>
    <div class="mt-2">
      <div class="d-flex justify-content-center flex-wrap">
        <span class="d-flex align-items-center justify-content-center">
          <span class="pe-1">
            <fa-icon class="icon" icon="download"></fa-icon>
          </span>
          <span>{{ downloadBookingDetailTemplate | translation: templateContent.TEMPLATE_CONTENT: lang }}:</span>
        </span>
        @for (booking of bookings; track booking; let index = $index; let last = $last) {
          <a
            class="ps-1"
            href="javascript:void(0)"
            (click)="downloadBookingDetail(booking?.bookingUUID)">
            {{ ('appointments.step3.appointment' | translate) + ' ' + (index + 1) + (last ? '' : ', ') }}
          </a>
        }
      </div>
    </div>
  </div>
</ng-template>

<ng-template #limitReachedTemplate>
  <div
    class="cbw-final-page-message mt-4 px-3 text-center"
    [innerHtml]="widgetSuccessPageEventBookingLimitReachedTemplate | translation: templateContent.TEMPLATE_CONTENT: lang | trustHtml"
  ></div>
</ng-template>
