import { booleanAttribute, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-cw-new-card',
  templateUrl: './cw-new-card.component.html',
  styleUrls: ['./cw-new-card.component.scss'],
  standalone: true,
})
export class CwNewCardComponent implements OnInit {

  protected shadowClass = 'shadow';
  @Input() protected customCardClass = '';
  @Input({ transform: booleanAttribute }) protected showShadow = true;
  @Input({ transform: booleanAttribute }) protected showHeader= false;
  @Input({ transform: booleanAttribute }) protected showBody= true;
  @Input({ transform: booleanAttribute }) protected showFooter= false;

  ngOnInit() {
    !this.showShadow && (this.shadowClass = '');
  }
}
