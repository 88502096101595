@if (customFields.length) {
  <div class="custom-field-container"
    [ngClass]="{
      'compact-custom-field-container': (widgetType === 'compact')
    }"
  >
    @for (field of customFields; track field; let index = $index; let isFirst = $first; let isLast = $last) {
      @if (field.is_hidden === 0) {
        <app-custom-field-view
          [(appointmentState)]="appointmentState"
          [widgetType]="widgetType"
          [customFields]="customFields"
          [field]="field"
          [lang]="lang"
          [selectedJsonCustomFields]="selectedJsonCustomFields"
          [customFieldValues]="customFieldValues"
          [customFieldValuesLSNameKey]="customFieldValuesLSNameKey"
          [isFirst]="isFirst"
          [isLast]="isLast"
        />
      }
    }
  </div>
}
