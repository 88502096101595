import { booleanAttribute, Component, Input } from '@angular/core';

@Component({
  selector: 'app-cw-card-header',
  templateUrl: './cw-card-header.component.html',
  styleUrls: ['./cw-card-header.component.scss'],
  standalone: true,
  imports: []
})
export class CwCardHeaderComponent {

  @Input({ transform: booleanAttribute }) showHeader = true;
  @Input() title: string;
  @Input() subtitle: string;
}
