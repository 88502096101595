<div class="cw-new-card bg-white w-100 {{ shadowClass }} {{ customCardClass }}">
  @if (showHeader) {
    <div class="cw-new-card-header">
      <ng-content select="[header]" />
    </div>
  }
  @if (showBody) {
    <div class="cw-new-card-body">
      <ng-content select="[body]" />
    </div>
  }
  @if (showFooter) {
    <div class="cw-new-card-footer">
      <ng-content select="[footer]" />
    </div>
  }
</div>
