import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { Params } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { TRANSLATION_TEMPLATES } from '../../../../constants/translation-templates-constants';
import { AppointmentServiceDao } from '../../../../db-models/appointment-service-dao';
import { CustomBookingMessageTemplate } from '../../../../db-models/widget-conf-dao';
import { AppointmentServiceCategoryModel } from '../../../../models/appointment-service-category';
import { Partner } from '../../../../models/global';
import { WidgetColorConf } from '../../../../models/widget-color.model';
import { ButtonComponent } from '../../../../shared/components/button/button.component';
import { CalioCurrencyPipe } from '../../../../shared/pipes/calio-currency.pipe';
import { HourReplacePipe } from '../../../../shared/pipes/hour-replace.pipe';
import { MinuteReplacePipe } from '../../../../shared/pipes/minute-replace.pipe';
import { ReplaceCommaPipe } from '../../../../shared/pipes/replace-comma.pipe';
import { TranslationPipe } from '../../../../shared/pipes/translation.pipe';
import { CwMultiLangTitleComponent } from '../../../common/theme/cw-multi-lang-title/cw-multi-lang-title.component';

const modules = [TranslateModule, NgbAccordionModule, FontAwesomeModule];
const components = [CwMultiLangTitleComponent, ButtonComponent];
const pipes = [CalioCurrencyPipe, HourReplacePipe, MinuteReplacePipe, ReplaceCommaPipe, TranslationPipe];

@Component({
  selector: 'app-cross-selling-services',
  templateUrl: './cross-selling-services.component.html',
  styleUrl: './cross-selling-services.component.scss',
  standalone: true,
  imports: [...modules, ...components, ...pipes],
})
export class CrossSellingServicesComponent implements OnInit {

  @Input() protected crossSellingServices = Array<AppointmentServiceCategoryModel>();
  @Input() protected lang: string;
  @Input() protected partner: Partner;
  @Input() protected widgetColorConf: WidgetColorConf;
  @Input() protected addCrossServiceToCartButtonText: CustomBookingMessageTemplate;

  @Output() private serviceChangeEvent = new EventEmitter<Params>();

  private translate = inject(TranslateService);

  protected readonly templateContent = TRANSLATION_TEMPLATES;
  protected activePanels = Array<string>();

  constructor() {
    this.translate.onLangChange.subscribe(lang => this.lang = lang.lang);
  }

  ngOnInit(): void {
    this.expandCollapseCategoriesBasedOnSetting();
  }

  private expandCollapseCategoriesBasedOnSetting(): void {
    const activeIds = Array<string>();
    if (Number(this.partner.widget_appointment_service_category_status) === 2) {
      for (let i = 0; i < this.crossSellingServices.length; i++) {
        activeIds.push(`static-${i}`);
      }
      this.activePanels = activeIds;
    } else if (Number(this.partner.widget_appointment_service_category_status) === 1) {
      activeIds.push(`static-0`);
      this.activePanels = activeIds;
    } else if (Number(this.partner.widget_appointment_service_category_status) === 0) {
      this.activePanels = [];
    }
  }

  protected addServiceToCart(service: AppointmentServiceDao): void {
    const event = { target: { checked: true } };
    this.serviceChangeEvent.emit({ event, serviceId: service.id, relatedServiceId: service.related_appointment_service_id });
  }
}
