import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { LoggerService } from './app/services/logger.service';
import { environment } from './environments/environment';
// import * as Sentry from "@sentry/angular-ivy";
// import { SentryConfig } from './sentry.config';

if (environment?.production) {
  enableProdMode();
  // Sentry.init(SentryConfig);
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(error => LoggerService.error(error));
