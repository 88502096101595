<app-cw-new-card
  [showBody]="true"
  [showHeader]="widgetType !== widgetConstants.COMPACT"
  [showShadow]="widgetType !== widgetConstants.COMPACT"
  [customCardClass]="customCSS"
>
  <div header>
    <app-cw-card-header
      [title]="templateTitle | translation: templateContent.TEMPLATE_CONTENT: lang"
      [subtitle]="templateSubTitle | translation: templateContent.TEMPLATE_CONTENT: lang"
    />
  </div>

  <div body [ngClass]="{ 'my-4': widgetType !== widgetConstants.COMPACT }">
    @if (showPaymentSuccessMsg) {
      <app-alert
        title="{{ 'summary_page_translations.payment_success_title' | translate }}"
        [text]="paymentSuccessMsg"
        type="success"
      />
    }

    @if (showPaymentFailedMsg) {
      <app-alert [text]="paymentFailedMsg | translate" type="danger" />
    }

    @if (!hidePaymentOptions) {
      <div class="ms-2 w-50">
        @if (isOnlinePaymentMandatory && paymentType === 'store' && priceAfterCouponReduction > 0) {
          <app-alert text="{{ 'summary_page_translations.paymentMandatory' | translate }}" type="warning" />
        }
      </div>

      @if (price > 0) {
        <div class="d-flex flex-column align-items-start cw-stripe-box">
          <div class="stripe-title payment-type cbcw-payment-selection-label">
            {{ 'summary_page_translations.payment_type' | translate }}:
          </div>
          <div class="stripe-field mt-2">
            <ng-select
              (change)="onChangePaymentType(paymentType);"
              [(ngModel)]="paymentType"
              [clearable]="false"
              [multiple]="false"
              [placeholder]="'summary_page_translations.choose_payment_method' | translate"
              [searchable]="false"
              class="payment-type-select"
              name="paymentType"
              required="true"
            >
              @if (isStripeEnabled) {
                <ng-option [value]="'creditcard'">
                  {{ 'summary_page_translations.creditcard' | translate }}
                </ng-option>
              }
              @if (isBexioEnabled) {
                <ng-option [value]="'invoice'">
                  {{ 'summary_page_translations.invoice' | translate }}
                </ng-option>
              }
              @if (isPaypalEnabled) {
                <ng-option [value]="'paypal'">
                  {{ paypalTemplate | translation: templateContent.TEMPLATE_CONTENT: lang }}
                </ng-option>
              }
              @if (isStoreEnabled) {
                <ng-option [value]="'store'">
                  {{ inStoreTemplate | translation: templateContent.TEMPLATE_CONTENT: lang }}
                </ng-option>
              }
              @if (isSaferpayEnabled) {
                <ng-option [value]="'saferpay'">
                  {{ saferpayTemplate | translation: templateContent.TEMPLATE_CONTENT: lang }}
                </ng-option>
              }
              @if (isLexOfficePaymentTypeEnabled) {
                <ng-option [value]="'lex_office_invoice'">
                  {{ 'summary_page_translations.lexoffice' | translate }}
                </ng-option>
              }
            </ng-select>

            @if (showPaymentTypeError && paymentType === 'paypal') {
              <div class="alert alert-danger summary-warning">
                {{ 'summary_page_translations.select_payment_method' | translate }}
              </div>
            }
            @if (hasValidCoupons) {
              <div class="mt-2">
                <a (click)="displayCouponField()" class="skip" href="javascript:void(0)">
                  {{ 'summary_page_translations.apply_coupon' | translate }}
                </a>
              </div>
            }
          </div>
        </div>
      }

      @if (showCouponField) {
        <div class="d-flex flex-column align-items-start cw-stripe-box mt-4">
          <div class="stripe-title">
            {{ 'coupon_credit_card_info.apply_coupon' | translate }}:
          </div>
          <div class="stripe-field mt-2">
            <app-coupon-form [partner]="partner" (couponFailedEvent)="couponFailedEvent.emit($event)"
              (couponSuccessEvent)="couponSuccessEvent.emit($event)" />
          </div>
        </div>
      }

      @if (isStripeEnabled && paymentType === 'creditcard' && price > 0) {
        <div class="d-flex flex-column align-items-start cw-stripe-box mt-4">
          <div class="stripe-title">
            {{ 'summary_page_translations.creditcard' | translate }}:
          </div>
          <div class="stripe-field mt-2">
            <app-stripe (resetStripeTokenEvent)="resetStripeTokenEvent.emit($event)"
              (stripeSuccessfulEvent)="stripeSuccessfulEvent.emit($event)" />
          </div>
        </div>
      }

      @if (isPaypalEnabled && paymentType === 'paypal' && price > 0) {
        <div class="cw-paypal-box mt-4" [ngClass]="{ 'px-0': widgetType === widgetConstants.COMPACT }">
          <app-cw-paypal
            [additionalGuests]="additionalGuests"
            [paypalSetting]="paypalSetting"
            [price]="price"
            [partner]="partner"
            [appoinrmentServicesAvailable]="appoinrmentServicesAvailable"
            [cart]="cart"
            [eventCart]="eventCart"
            [widgetType]="widgetType"
            (paypalSuccessfulEvent)="onPaypalSuccessfulEvent($event)"
            (resetPaypalEvent)="resetPaypalEvent.emit($event)"
          />
        </div>
      }

      @if (isSaferpayEnabled && paymentType === 'saferpay' && price > 0) {
        <div class="mt-4">
          <app-cw-saferpay
            #cwSaferpay
            [price]="price"
            [partner]="partner"
            [cart]="cart"
            [eventCart]="eventCart"
            [widgetType]="widgetType"
            [appoinrmentServicesAvailable]="appoinrmentServicesAvailable"
            (saferpaySuccessfulEvent)="onSaferpaySuccessfulEvent($event)"
            (resetSaferpayEvent)="resetSaferpayEvent.emit()"
          />
        </div>
      }
    }
  </div>
</app-cw-new-card>
