import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toHour',
  standalone: true
})
export class HourReplacePipe implements PipeTransform {
  transform(value: number): string {
    value = value * 60;
    const h = Math.floor(value / 60);
    const hDisplay = h;
    return hDisplay + '';
  }
}
