import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceQuestionmarkFromEndOfString',
  standalone: true
})
export class ReplaceQuestionmarkFromEndOfStringPipe implements PipeTransform {
  transform(value: string): string {
    if (value?.endsWith("?")) {
      value = value.slice(0, -1);
    }
    return value;
  }
}
