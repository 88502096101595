<div class="image-preview-wrapper">
  <div class="model-close-button">
    <button
      type="button"
      class="close"
      aria-label="Close"
      (keydown.enter)="$event.preventDefault()"
      (keydown.escape)="close();"
      (click)="close();"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  @if (imageUrl) {
    <img [src]="imageUrl" width="100%" alt="" />
  }

  @if (!imageUrl) {
    <p>Image source is missing.</p>
  }
</div>
