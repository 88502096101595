<!--Workers Loader-->
@if (appointmentState.date && showAppointmentSlots) {
  <div>
    @if (noAppointments) {
    <app-alert text="{{ noFreeAppointments | translation: templateContent.TEMPLATE_CONTENT: lang }}" type="warning" />
    }

    @if (!noAppointments) {
    <div>
      <form class="" id="checkboxForm" name="appCheckboxForm" #appCheckboxForm="ngForm">
        @if (!noAppointments) {
        <div>
          @if (hasGroups) {

          <mat-tab-group [selectedIndex]="0" [dynamicHeight]="true" [disableRipple]="true" class="slots-group">
            @for (appointmentsGroup of freeAppointments; track appointmentsGroup; let index = $index) {
            @if (appointmentsGroup.widgetGroup) {
            <mat-tab>
              <ng-template mat-tab-label>
                {{ appointmentsGroup.widgetGroup | translation: 'name' : lang }}
                @if (appointmentsGroup.selectedSlots > 0) {
                <span class="badge tabs-slot-counter ms-2">
                  {{ appointmentsGroup.selectedSlots }}
                </span>
                }
              </ng-template>
              <div class="row">
                <ng-container
                  *ngTemplateOutlet="slotsBlock; context: { $implicit: appointmentsGroup, index: index }"></ng-container>
              </div>
            </mat-tab>
            }
            }
          </mat-tab-group>
          }

          @if (!hasGroups) {
            @for (appointmentsGroup of freeAppointments; track appointmentsGroup; let index = $index) {
            <div class="row">
              <ng-container
                *ngTemplateOutlet="slotsBlock; context: { $implicit: appointmentsGroup, index: index }"></ng-container>
            </div>
            }
          }
        </div>
        }
      </form>
    </div>
    } @else {
      @if (!noAppointments) {
      <app-loader loaderType="{{ appointmentConstant.WORKER_LOADER }}" />
      }
    }

    <ng-template #slotsBlock let-appointmentsGroup let-index="index">
      @for (appointment of appointmentsGroup.appointments; track appointment; let appointmentindex = $index) {
        @if (appointmentsGroup?.loadMore === true || appointmentindex < availableSlotsCount) {
          <div
            class="col-xs-12 col-sm-6"
            [ngClass]="{ 'slot-box-group': hasGroups }"
          >
            <div
              class='cbw-appointment-card'
              [ngClass]="{
                'cbw-appointment-card-disabled': terminConflict(appointment),
                'cbw-selected-appointment-card': isTerminInCart(createCartItemId(appointment?.worker?.id, appointmentState.date, appointment.short_start))
              }"
              (click)="updateCartManually(appointment.short_start + '_' + appointment?.worker?.id)"
            >
              <div
                class="cbw-check-box-container w-100"
                (click)="updateCartManually(appointment.short_start + '_' + appointment?.worker?.id);$event.stopPropagation()"
              >
                <app-cw-checkbox-radio-button
                  class="w-100"
                  customId="{{ appointment.short_start + '_' + appointment?.worker?.id }}"
                  name="{{ 'freeAppointments' + appointment.short_start }}"
                  value="{{ appointment.short_start }}"
                  [checked]="isTerminInCart(createCartItemId(appointment?.worker?.id, appointmentState.date, appointment.short_start))"
                  [isCheckbox]="true"
                  containerClass="justify-content-center"
                  labelClass="w-100 justify-content-center"
                  (selectionChange)="updateCart($event.event, appointment)"
                  (click)="$event.stopPropagation()"
                  [isDisabled]="terminConflict(appointment)"
                >
                  <div class="d-flex flex-column justify-content-center align-items-start">
                    @if (appointmentState.worker === 0 && partner.anyone_feature_hide_ressource === 0) {
                    <span class="cbw-worker-name text-center w-100">
                      @if (appointment?.worker?.is_resource === 1) {
                        @if ((appointment?.worker | translation: 'booking_label': lang)) {
                          {{ appointment?.worker | translation: 'booking_label': lang }}
                        }
                        @if (!(appointment?.worker | translation: 'booking_label': lang)) {
                          {{ appointment?.worker | translation: 'resource_name': lang }}
                        }
                      }

                      @if (appointment?.worker?.is_resource === 0) {
                        {{ appointment?.worker?.resource_name }}
                      }
                    </span>
                    }

                    <span class="cbw-title text-center w-100">
                      <div>{{ appointment.start | dateutc:timeFormat }} - {{ appointment.end | dateutc:timeFormat }}</div>
                      @if (partner?.widget_show_location_on_appointment_proposal === 1 && appointment?.location) {
                        <div class="cbw-location location">
                          <div>
                            <img class="cbw-icon icon" src="{{ deployUrl + '/assets/images/location.svg' }}" />
                          </div>
                          <div>
                            {{ appointment?.location }}
                          </div>
                        </div>
                      }
                    </span>

                  </div>
                </app-cw-checkbox-radio-button>

                <div class="next-arrow-container">
                  @if (isTerminInCart(createCartItemId(appointment?.worker?.id, appointmentState.date,
                  appointment.short_start))) {
                    <span (click)="gotoNextPage()">
                      <fa-icon class="icon cbw-next-arrow-button d-flex" icon="chevron-circle-right"></fa-icon>
                    </span>
                  }
                </div>
              </div>
            </div>
          </div>
        }
      }

      @if (
        (
          appointmentsGroup?.loadMore === false ||
          appointmentsGroup?.loadMore === undefined
        ) &&
        appointmentsGroup?.appointments?.length > availableSlotsCount
      ){
        <div class="col-md-12 text-center load-more-button">
          <a class="load-more-link" href="javascript:void(0)" (click)="loadMore(index)">
            {{ 'load_more' | translate }}
          </a>
        </div>
      }
    </ng-template>
  </div>
}
