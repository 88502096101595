import { NgClass, NgStyle, NgTemplateOutlet } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, DestroyRef, EventEmitter, Input, Output, booleanAttribute, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { finalize } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { EVENT_CONSTANT } from '../../../../constants/event-constants';
import { TRANSLATION_TEMPLATES } from '../../../../constants/translation-templates-constants';
import { WIDGET_CONSTANTS } from '../../../../constants/widget-constants';
import { CategoryDbModel } from '../../../../db-models/categories-db.model';
import { EventDao, EventPaginationDao } from '../../../../db-models/event-dao';
import { EventDateDao } from '../../../../db-models/event-date-dao';
import { StoreDbModel } from '../../../../db-models/stores-dao';
import { CustomBookingMessageTemplate } from '../../../../db-models/widget-conf-dao';
import { Partner } from '../../../../models/global';
import { EventState } from '../../../../models/state.model';
import { WidgetColorConf } from '../../../../models/widget-color.model';
import { CategoryService } from '../../../../services/category.service';
import { LoggerService } from '../../../../services/logger.service';
import { StoreService } from '../../../../services/store.service';
import { AlertComponent } from '../../../../shared/components/alert/alert.component';
import { ButtonComponent } from '../../../../shared/components/button/button.component';
import { LoaderComponent } from '../../../../shared/components/loader/loader.component';
import { NewAlertComponent } from '../../../../shared/components/new-alert/new-alert.component';
import { NgxClampComponent } from '../../../../shared/components/ngx-clamp/ngx-clamp.component';
import { CalioTranslationSortPipe } from '../../../../shared/pipes/calio-translation-sort.pipe';
import { IsEmptyStringPipe } from '../../../../shared/pipes/is-empty-string.pipe';
import { TranslationPipe } from '../../../../shared/pipes/translation.pipe';
import { TrustHtmlPipe } from '../../../../shared/pipes/trust-html.pipe';

@Component({
  selector: 'app-event-card',
  templateUrl: './event-card.component.html',
  styleUrls: ['./event-card.component.scss'],
  standalone: true,
  imports: [AlertComponent, NewAlertComponent, FormsModule, NgClass, FontAwesomeModule, NgxClampComponent, NgTemplateOutlet, NgStyle, ButtonComponent, LoaderComponent, IsEmptyStringPipe, TranslateModule, TranslationPipe, TrustHtmlPipe, CalioTranslationSortPipe]
})
export class EventCardComponent {

  @Input({ transform: booleanAttribute }) set noEvents(noEvents: boolean) {
    this._noEvents = noEvents;
    this._noEvents && this.handelEventLoading();
  };
  @Input({ transform: booleanAttribute }) set noActiveEvents(noActiveEvents: boolean) {
    this._noActiveEvents = noActiveEvents;
    this._noActiveEvents && this.handelEventLoading();
  };
  @Input({ transform: booleanAttribute }) set noFilterEvents(noFilterEvents: boolean) {
    this._noFilterEvents = noFilterEvents;
    this._noFilterEvents && this.handelEventLoading();
  };
  @Input() set events(events: EventDao[]) {
    this._events = events;
    this._events?.length && this.handelEventLoading();
  };
  @Input() shownMsg = [];
  @Input() eventState: EventState;
  @Input() widgetColorConf: WidgetColorConf;
  @Input() set widgetTemplates(widgetTemplates: CustomBookingMessageTemplate[]) {
    this._widgetTemplates = widgetTemplates;
    if (this._widgetTemplates?.length > 0 && this._partner) {
      this.setupTemplates();
    }
  };
  @Input() screenType = 'LIST';
  @Input() set partner(partner: Partner) {
    this._partner = partner;
    this.isGroupAppointmentStoreFeatureEnabled = Number(this._partner?.is_group_appointment_store_feature_enabled);
    this.isGroupAppointmentCategoryFeatureEnabled = Number(this._partner?.is_group_appointment_category_feature_enabled);
    this.getMappedEventStores();
  };
  @Input() lang: string;
  @Input() hideGridSwitcher = 'false';
  @Input() showEventsListing = true;
  @Input() paginationData: EventPaginationDao;
  @Input() selectedEventStores = Array<string>();
  @Input() selectedEventCategories = Array<string>();

  @Output() navigateToEvent = new EventEmitter<string>();
  @Output() resetFormEvent = new EventEmitter<string>();
  @Output() autoSelectSlotAndJumpToSummaryEvent = new EventEmitter<void>();
  @Output() loadMoreEvents = new EventEmitter<EventPaginationDao>();
  @Output() eventFilterChangeEvents = new EventEmitter<[string[], string[]]>();

  private translate = inject(TranslateService);
  private categoryService = inject(CategoryService);
  private storeService = inject(StoreService);
  private destroyRef = inject(DestroyRef);

  readonly logoBaseUrl = environment.logoBaseUrl;
  readonly eventConstants = EVENT_CONSTANT;
  readonly eventImgBaseUrl = environment.eventImgBaseUrl;
  readonly deployUrl = environment.deployUrl;
  readonly eventImgFallback = environment.eventImgFallback;
  readonly widgetConstants = WIDGET_CONSTANTS;
  readonly templateContent = TRANSLATION_TEMPLATES;

  _events: EventDao[];
  widgetBookButtonLabelTemplate: CustomBookingMessageTemplate;
  widgetNoFreeSlotsLabelTemplate: CustomBookingMessageTemplate;
  noEventsLabelTemplate: CustomBookingMessageTemplate;
  hiddenEventsLabelTemplate: CustomBookingMessageTemplate;
  evnetDisabledErrorLabelTemplate: CustomBookingMessageTemplate;
  isGroupAppointmentStoreFeatureEnabled = 0;
  isGroupAppointmentCategoryFeatureEnabled = 0;
  eventStores: StoreDbModel[];
  eventCategories: CategoryDbModel[];
  isEventsCategoriesLoading = false;
  _widgetTemplates: CustomBookingMessageTemplate[] = [];
  _partner: Partner;
  isEventsLoading = true;
  _noEvents = false;
  _noActiveEvents = false;
  _noFilterEvents = false;

  constructor() {
    this.translate.onLangChange.subscribe(lang => this.lang = lang.lang);
  }

  private handelEventLoading(): void {
    if (this.paginationData?.isLoading) {
      this.paginationData.isLoading = false;
    }
    this.isEventsLoading = false;
  }

  private navigateTo(page: string): void {
    this.navigateToEvent.next(page);
  }

  resetForm(): void {
    this.resetFormEvent.next('next');
  }

  getMappedEventStores(): void {
    if (this.isGroupAppointmentStoreFeatureEnabled) {
      this.storeService.getMappedEventStores()
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: stores => {
            this.eventStores = stores;
            this.selectedEventStores = this.selectedEventStores?.filter(store => stores?.some(cat => cat?.uuid === store));
            this.getMappedEventCategories(this.selectedEventStores);
          },
          error: (error: HttpErrorResponse) => LoggerService.error(error)
        })
    } else {
      this.getMappedEventCategories(this.selectedEventStores);
    }
  }

  getMappedEventCategories(storeUuid: string[] = null): void {
    if (this.isGroupAppointmentCategoryFeatureEnabled) {
      this.isEventsCategoriesLoading = true;
      this.eventCategories = [];

      // converting uuids to ids
      const store_id = [];
      this.eventStores?.forEach(store => storeUuid?.includes(store?.uuid) && store_id.push(store.id));

      this.categoryService.getMappedEventCategories(store_id)
        .pipe(
          finalize(() => this.isEventsCategoriesLoading = false),
          takeUntilDestroyed(this.destroyRef)
        ).subscribe({
          next: categories => {
            this.eventCategories = categories;
            this.selectedEventCategories = this.selectedEventCategories?.filter(category => categories?.some(cat => cat?.uuid === category));
            this.eventFilterChange();
          },
          error: (error: HttpErrorResponse) => LoggerService.error(error)
        })
    } else {
      this.eventFilterChange();
    }
  }

  eventFilterChange(): void {
    this.eventFilterChangeEvents.emit([this.selectedEventStores, this.selectedEventCategories]);
  }

  onStoreSelect(storeUuid?: string): void {
    this.isEventsLoading = true;
    if (!storeUuid) {
      this.selectedEventStores = [];
    } else {
      const hasAlreadySelected = this.selectedEventStores.indexOf(storeUuid);
      if (hasAlreadySelected !== -1) {
        this.selectedEventStores.splice(hasAlreadySelected, 1);
      } else {
        this.selectedEventStores.push(storeUuid);
      }
    }

    this.getMappedEventCategories(this.selectedEventStores);
  }

  onCategorySelect(categoryUuid?: string): void {
    this.isEventsLoading = true;
    if (!categoryUuid) {
      this.selectedEventCategories = [];
    } else {
      const hasAlreadySelected = this.selectedEventCategories.indexOf(categoryUuid);
      if (hasAlreadySelected !== -1) {
        this.selectedEventCategories.splice(hasAlreadySelected, 1);
      } else {
        this.selectedEventCategories.push(categoryUuid);
      }
    }
    this.eventFilterChange();
  }

  selectEvent(event: EventDao): void {
    this.eventState.eventId = event.uuid;
    this.eventState.date = null;
    this.eventState.is_multi_day = event.is_multi_day;
    this.eventState.number_allowed_additional_guests = event.number_allowed_additional_guests;
    this.eventState.additional_guests_enabled = event.additional_guests_enabled;
    this.eventState.meeting_type_id = event.meeting_type_id;
    this.eventState.meeting_type = event.meeting_type;
    this.eventState.place = event.place;
    this.eventState.is_widget_event_guest_email_required = Number(event.is_widget_event_guest_email_required);
    this.eventState.is_widget_event_guest_phone_required = Number(event.is_widget_event_guest_phone_required);
    this.eventState.is_agenda_view_enabled = Number(event.is_agenda_view_enabled);
    if (event.has_one_slot === 1 && event?.slots?.length) {
      let foundedSlot: EventDateDao;
      for (const slot of event.slots) {
        if (slot.start && !moment().isAfter(slot.start)) {
          foundedSlot = Object.assign({}, slot);
          break;
        }
      }

      if (!foundedSlot) {
        this.navigateTo(this.eventConstants.DATE);
      }

      if (foundedSlot.regular_booking_count < foundedSlot.regular_capacity) {
        this.eventState.foundedSlot = foundedSlot;
        this.eventState.has_one_slot = 1;
        this.eventState.slots = event.slots;
        this.autoSelectSlotAndJumpToSummaryEvent.emit();
      } else {
        LoggerService.warn('[Debug] Automatic jump is disabled because regular booking count is greater or equal to regular capacity');
        this.navigateTo(this.eventConstants.DATE);
      }
    } else {
      this.navigateTo(this.eventConstants.DATE);
    }
  }

  toggleView(view: string): void {
    this.screenType = view;
  }

  setupTemplates(): void {
    this.widgetBookButtonLabelTemplate = this._widgetTemplates.find(template => template.identifier === WIDGET_CONSTANTS.EVENT_WIDGET_LABELS.WIDGET_EVENT_BOOK_LABEL);
    this.widgetBookButtonLabelTemplate && (this.widgetBookButtonLabelTemplate.is_multi_language = 1);

    this.widgetNoFreeSlotsLabelTemplate = this._widgetTemplates.find(template => template.identifier === WIDGET_CONSTANTS.EVENT_WIDGET_LABELS.WIDGET_EVENT_NO_MORE_FREE_SLOTS_LABEL);
    this.widgetNoFreeSlotsLabelTemplate && (this.widgetNoFreeSlotsLabelTemplate.is_multi_language = 1);

    this.noEventsLabelTemplate = this._widgetTemplates.find(template => template.identifier === WIDGET_CONSTANTS.EVENT_WIDGET_LABELS.WIDGET_EVENT_NO_EVENTS_LABEL);
    this.noEventsLabelTemplate && (this.noEventsLabelTemplate.is_multi_language = 1);

    this.hiddenEventsLabelTemplate = this._widgetTemplates.find(template => template.identifier === WIDGET_CONSTANTS.EVENT_WIDGET_LABELS.WIDGET_EVENT_HIDDEN_EVENTS_LABEL)
    this.hiddenEventsLabelTemplate && (this.hiddenEventsLabelTemplate.is_multi_language = 1);

    this.evnetDisabledErrorLabelTemplate = this._widgetTemplates.find(template => template.identifier === WIDGET_CONSTANTS.EVENT_WIDGET_LABELS.WIDGET_EVENT_DISABLED_ERROR_LABEL)
    this.evnetDisabledErrorLabelTemplate && (this.evnetDisabledErrorLabelTemplate.is_multi_language = 1);
  }

  loadMoreEventsFn(): void {
    if (this.paginationData.totalCounts <= (Number(this.paginationData.limit) * (Number(this.paginationData.page) + 1))) {
      this.paginationData.hideLoadMoreButton = true;
      return;
    }

    this.paginationData.isLoading = true;
    this.paginationData.page += 1;
    this.paginationData.offset = Number(this.paginationData.limit) * Number(this.paginationData.page);
    this.loadMoreEvents.emit(this.paginationData);
  }
}
