import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { LoggerService } from '../../services/logger.service';

@Pipe({
  name: 'trustHtml',
  standalone: true
})
export class TrustHtmlPipe implements PipeTransform {
  constructor(
    private sanitizer: DomSanitizer,
    private translate: TranslateService,
  ) {
  }

  transform(value: string, type = 'html'): any {
    if (/<(basefont|hr|input|source|frame|param|area|meta|!--|col|link|option|base|img|wbr|!DOCTYPE|abbr|acronym|address|applet|article|aside|audio|bdi|bdo|big|blockquote|body|button|canvas|caption|center|cite|code|colgroup|command|datalist|dd|del|details|dfn|dialog|dir|dl|dt|em|embed|fieldset|figcaption|figure|font|footer|form|frameset|head|header|hgroup|h1|h2|h3|h4|h5|h6|html|iframe|ins|kbd|keygen|label|legend|map|mark|menu|meter|nav|noframes|noscript|object|ol|optgroup|output|pre|progress|q|rp|rt|ruby|s|samp|script|section|select|small|style|sub|summary|sup|table|tbody|td|textarea|tfoot|th|thead|time|title|tr|track|tt|var|video) .+?>/g.test(value)) {
      return `<span class="vulnerable-error-msg">${this.translate.instant('alert_messages.xss_error_message')}</span>`; // Error message
    }

    // Anchor tags
    if (/<(a|b|i|u|br|div|p|ul|li|span|strike|strong) .+?>/g.test(value)) {
      try {
        if (value.indexOf('javascript:') > -1) {
          return `<span class="vulnerable-error-msg">${this.translate.instant('alert_messages.xss_error_message')}</span>`; // Error message
        }
      } catch (e) {
        LoggerService.error(e);
      }
    }

    switch (type) {
      case 'html':
        return this.sanitizer.bypassSecurityTrustHtml(value);
      case 'style':
        return this.sanitizer.bypassSecurityTrustStyle(value);
      case 'script':
        return this.sanitizer.bypassSecurityTrustScript(value);
      case 'url':
        return this.sanitizer.bypassSecurityTrustUrl(value);
      case 'resourceUrl':
        return this.sanitizer.bypassSecurityTrustResourceUrl(value);
      default:
        throw new Error(`Invalid safe type specified: ${type}`);
    }
  }
}
