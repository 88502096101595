<app-custom-field
  [field]="field"
  [lang]="lang"
  [customFieldValues]="customFieldValues"
  [customFieldValuesLSNameKey]="customFieldValuesLSNameKey"
  [widgetType]="widgetType"
  (checkboxChangeEvent)="onCheckboxChangeEvent($event)"
  (radioButtonChangeEvent)="onRadioButtonChangeEvent($event)"
/>

@if (field?.customFieldDependencies?.length > 0) {
  <ng-container *ngTemplateOutlet="recursiveListTmpl; context: { customFieldDependencies: field?.customFieldDependencies }" />
}

@if (showDependenciesGhostElement) {
  <div class="skeleton-box-card mt-4">
    <div class="row">
      @for (dependenciesGhostElement of dependenciesGhostElements; track dependenciesGhostElement) {
        <div class="col-md-12 mb-4">
          <div class="d-flex stats-card shadow">
            <div class="skeleton-animation w-100 d-flex justify-content-center flex-column">
              <div class="mt-2">
                <div class="stat-box-1"></div>
                <div class="stat-box m-2"></div>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  </div>
}

<ng-template #recursiveListTmpl let-customFieldDependencies="customFieldDependencies">
  @for (dependencyCustomField of customFieldDependencies; track dependencyCustomField) {
    <div>
      <app-custom-field
        [field]="dependencyCustomField"
        [lang]="lang"
        [customFieldValues]="customFieldValues"
        [customFieldValuesLSNameKey]="customFieldValuesLSNameKey"
        [widgetType]="widgetType"
        (checkboxChangeEvent)="onCheckboxChangeEvent($event)"
        (radioButtonChangeEvent)="onRadioButtonChangeEvent($event)"
      />
      @if (dependencyCustomField?.customFieldDependencies?.length) {
        <div>
          <ng-container *ngTemplateOutlet="recursiveListTmpl; context: { customFieldDependencies: dependencyCustomField?.customFieldDependencies }" />
        </div>
      }
    </div>
  }
</ng-template>
