import { AppointmentServiceDao } from '../db-models/appointment-service-dao';
import { EventDateDao } from '../db-models/event-date-dao';
import { StoresDao } from '../db-models/stores-dao';
import { EventDao } from './../db-models/event-dao';

export interface CartItem {
  storeId: number;
  storeName: string;
  store: StoresDao;
  date: Date;
  startTime: string;
  shortStart: number;
  endTime: string;
  workerId: number;
  servicesIds: number[];
  cartItemId: string;
  workerName: string;
  workerEmail: string;
  total: CartItemTotal;
  relatedServiceIds: number[];
  relatedServices: AppointmentServiceDao[];
  relatedAppointmentCardId: number;
  referenceServices: Array<CartItemReferenceSerice>;
  parentServiceId: number;
  location?: string;
  workerUuids: string[];
}

export interface CartItemReferenceSerice {
  serviceId: number;
  serviceName: string;
  serviceCategoryId: number;
}

export interface CartItemTotal {
  services?: object[];
  totalDuration?: number;
  totalPrice?: number;
  tax?: number;
  isPriceFromActivated?: number;
  duration?: number;
  price?: any;
  taxPrice?: any;
}

export interface AppointmentBookItem {
  partnerId: number;
  services: string;
  worker_id: number;
  worker_uuids: string[];
  start: string;
  end: string;
  gender: string;
  title: number,
  prename?: string;
  lastname?: string;
  captcha?: string;
  email?: string;
  phone?: string;
  mobile?: string;
  company_name?: string;
  comment?: string;
  internal_comment?: string;
  customer_id?: number;
  customer_uuid?: string;
  customFieldValues: {
    custom_field_id: number;
    type: string;
    value?: any;
    file_ids?: any;
  }[];
  street?: string;
  zip?: string;
  city?: string;
  country: number,
  create_account?: boolean;
  user_selected_language?: string;
  user_browser_language?: string;
  // TODO STRIPE_MIGRATION:
  // stripe_payment_method_id: string;
  stripe_card_token?: string;
  coupon_code?: string;
  payment_type?: string;
  booker_worker_id?: number;
  customer_notification_preference?: string;
  meeting_type_id?: number;
  utm_source?: string;
  utm_content?: string;
  utm_term?: string;
  utm_medium?: string;
  utm_campaign?: string;
  paypal_order_id?: string;
  paypal_payer_id?: string;
  paypal_raw_data?: string;
  paypal_status?: string;
  paypal_capture_id?: string;
  saferpay_transaction_id?: string;
  saferpay_payment_method?: string;
  broker_prename?: string;
  broker_lastname?: string;
  broker_email?: string;
  broker_company?: string;
  broker_phone?: string;
  broker_mobile?: string;
  broker_street?: string;
  broker_zip?: string;
  broker_city?: string;
  token: string;
  location?: string;
  is_internal?: boolean;

  // conference related properties
  booking_link_uuid?: string;

  // client side property
  referenceServices?: Array<CartItemReferenceSerice>;
  resource_name?: string;
  store_id?: number;
  store_name?: string;
  worker_email?: string;
}

export interface EventCartItem {
  slotId: number;
  workerId: number;
  date: Date;
  startTime: Date;
  endTime: Date;
  duration: number;
  currentEvent: CurrentEvent;
  eventId: string | number;
  category: string;
  tempSlotOptions?: number[];
  tempSelectLabel?: string;
  tempSelectOptionLabel?: string;
  selectedSlot?: { slot_id: number; amount: number };
  is_waitinglist: boolean;
  is_waitinglist_enabled: boolean;
  regular_booking_count?: number;
  regular_capacity?: number;
  waitinglist_booking_count?: number;
  waitinglist_capacity?: number;
  available_regular_capacity?: number;
  available_waitinglist_capacity?: number;
  referenceEvent?: EventDao;
  childSlots?: EventDateDao[];
  location?: string;
  is_widget_event_guest_email_required?: number;
  is_widget_event_guest_phone_required?: number;
  meeting_type_id?: number;
}

export interface CurrentEvent {
  title: string;
  description: string;
  place: string;
  image: string;
  price: number;
  tax: number;
  taxRate: number;
}

export interface EventBookItem {
  event_id: string | number;
  event_name: string;
  category: string;
  slot_id: number;
  gender: string;
  title: number;
  prename: string;
  lastname: string;
  email: string;
  phone: string;
  mobile: string;
  customer_notification_preference: string;
  customer_id?: number;
  customer_uuid?: string;
  customFieldValues: {
    custom_field_id: number;
    type: string;
    value?: any;
    file_ids?: any;
  }[];
  street?: string;
  zip?: string;
  city?: string;
  country: number;
  create_account?: boolean;
  user_selected_language?: string;
  user_browser_language?: string;
  company_name?: string;
  comment?: string;
  internal_comment?: string;
  guests?: GuestModel[];
  stripe_card_token?: string;
  childSlots?: { slot_id: number; amount: number }[];
  is_waitinglist: boolean;
  coupon_code?: string;
  payment_type?: string;
  utm_source?: string;
  utm_content?: string;
  utm_term?: string;
  utm_medium?: string;
  utm_campaign?: string;

  paypal_order_id?: string;
  paypal_payer_id?: string;
  paypal_raw_data?: string;
  paypal_status?: string;
  paypal_capture_id?: string;

  meeting_type_id?: number;

  saferpay_transaction_id?: string;
  saferpay_payment_method?: string;
  worker_id?: number;
}

export interface ResourceCartItem {
  resourceId: number;
  startDate: Date;
  endDate: Date;
  startTime: string;
  endTime: string;
  resourceDetails: object;
}

export interface ResourceBookItem {
  resourceId: number;
  start: string;
  end: string;
  prename: string;
  lastname: string;
  email: string;
  phone: string;
  comment: string;
  customer_id?: number;
  customer_uuid?: string;
  customFieldValues: { custom_field_id: number; value: string }[];
  street?: string;
  zip?: string;
  city?: string;
  create_account?: boolean;
}

export class GuestModel {
  prename: string;
  lastname: string;
  email?: string;
  phone?: string;
}
