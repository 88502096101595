import { Pipe, PipeTransform } from '@angular/core';
import { StoresDao } from '../../db-models/stores-dao';
import { storeValues } from '../../constants/template-placeholder-constants';

@Pipe({
  name: 'replaceTemplatePlaceholder',
  standalone: true
})
export class ReplaceTemplatePlaceholderPipe implements PipeTransform {

  transform(value: string, selectedStore: StoresDao): any {
    if (selectedStore) {
      return this.replaceStorePlaceholders(value, selectedStore);
    }
    return value;
  }

  replaceStorePlaceholders(value: string, selectedStore: StoresDao): string {
    if (storeValues.STORE_NAME) {
      if (selectedStore.name) {
        value = value.replace(storeValues.STORE_NAME, selectedStore.name);
      } else {
        value = value.replace(storeValues.STORE_NAME, '');
      }
    }
    if (storeValues.STORE_STREET) {
      if (selectedStore.street) {
        value = value.replace(storeValues.STORE_STREET, selectedStore.street);
      } else {
        value = value.replace(storeValues.STORE_STREET, '');
      }
    }
    if (storeValues.STORE_ZIP) {
      if (selectedStore.zip) {
        value = value.replace(storeValues.STORE_ZIP, selectedStore.zip + '');
      } else {
        value = value.replace(storeValues.STORE_ZIP, '');
      }
    }
    if (storeValues.STORE_CITY) {
      if (selectedStore.city) {
        value = value.replace(storeValues.STORE_CITY, selectedStore.city);
      } else {
        value = value.replace(storeValues.STORE_CITY, '');
      }
    }
    if (storeValues.STORE_LATITUDE) {
      if (selectedStore.lat) {
        value = value.replace(storeValues.STORE_LATITUDE, selectedStore.lat + '');
      } else {
        value = value.replace(storeValues.STORE_LATITUDE, '');
      }
    }
    if (storeValues.STORE_LONGITUDE) {
      if (selectedStore.lng) {
        value = value.replace(storeValues.STORE_LONGITUDE, selectedStore.lng + '');
      } else {
        value = value.replace(storeValues.STORE_LONGITUDE, '');
      }
    }
    if (storeValues.STORE_EMAIL) {
      if (selectedStore.email) {
        value = value.replace(storeValues.STORE_EMAIL, selectedStore.email);
      } else {
        value = value.replace(storeValues.STORE_EMAIL, '');
      }
    }
    if (storeValues.STORE_PHONE) {
      if (selectedStore.phone) {
        value = value.replace(storeValues.STORE_PHONE, selectedStore.phone);
      } else {
        value = value.replace(storeValues.STORE_PHONE, '');
      }
    }
    if (storeValues.STORE_PLACE) {
      if (selectedStore.place) {
        value = value.replace(storeValues.STORE_PLACE, selectedStore.place);
      } else {
        value = value.replace(storeValues.STORE_PLACE, '');
      }
    }

    return value;
  }

}
