import { CustomerProfile } from '../models/global';
import { LanguageDao } from './language.model';

export class PartnerDao {
  id: number;
  name: string;
  booking_name: string;
  bookingName: string;
  logo: string;
  currency: string;
  micro_partner_description: string;
  url: string;
  hide_website_on_bookingsite: number;
  appointment_booking_info: string;
  event_slot_booking_info: string;
  courtesy_form: string;
  street: string;
  language_id: number;
  language_identifier: string;
  language: LanguageDao;
  country_id: number;
  zip: string;
  city: string;
  phone: string;
  hide_mobile_on_bookingsite: number;
  email: string;
  is_business: number;
  widget_header_background_color: string;
  widget_footer_background_color: string;
  widget_text_color: string;
  widget_footer_button_color: string;
  widget_header_active_color: string;
  widget_background_color: string;
  appointment_booking_enabled: number;
  event_booking_enabled: number;
  resource_booking_enabled: number;
  booking_interval: number;
  ical_hash: string;
  egal_wer_show: number;
  show_address_on_widget: number;
  show_phone_on_widget: number;
  show_mobile_on_widget: number;
  is_cart_supported: number;
  show_message_on_widget: number;
  appointment_lead_time: number;
  event_lead_time: number;
  hide_back_on_bookingsite: number;
  hide_book_additional_appointment_button: number;
  hide_login_on_bookingsite: number;
  country: CountryDao;
  token: string;
  worker?: WorkerDao;
  subscription: SubscriptionDao;
  analytics: AnalyticsDao;
  widget_booking_info: WidgetBookingInfoDao;
  allow_multiple_services_bookings: number;
  // templates: CustomBookingMessageTemplate[] = [];
  hide_resource_step: number;
  hide_mail_resource: number;
  future_booking_threshold_appointments: number;
  future_booking_threshold_events: number;
  bookingwidget_enabled: number;
  widget_appointment_service_category_status: number;
  hide_widget_cart_functionality_message: number;
  supported_widget_languages: string[];
  show_stores_as_dropdown: number;
  enable_store_postcode_feature: number;
  ask_for_customer_notification_preference: number;
  disable_automatic_search: number;
  uuid: string;
  enable_services_search: number;
  widget_hide_resource_step_for_default_workers: number;
  widget_disable_search_all_resource_button: number;
  activate_phone_number_verification_feature: number;
  activate_mobile_number_verification_feature: number;
  widget_email_shown: number;
  widget_email_required: number;
  widget_show_lead_creation_button: number;
  show_distance_in_stores: number;
  automatic_store_zip_search: number;
  show_salutation_in_widget: number;
  internal_widget_email_shown: number;
  internal_widget_email_required: number;
  show_inline_description: number;
  is_maintenance_mode_enabled: number;
  show_privacy_message_checkbox: number;
  is_round_robin_feature_enabled: number;
  widget_address_required: number;
  is_customer_profiles_feature_enabled: number;
  is_redirect_after_booking_enabled: number;
  successful_booking_redirect_url: string;
  widget_salutation_required: number;
  customer_profiles: CustomerProfile[] = [];
  widget_phone_required: number;
  widget_message_required: number;
  internal_widget_store_search_list: number;
  adobe_launch_tracking_url?: string;
  hide_event_capacity?: number;
  scroll_to_element_feature_is_enabled?: number;
  widget_company_name_required?: number;
  widget_company_name_shown?: number;
  widget_internal_comment_required?: number;
  widget_internal_comment_shown?: number;
  is_widget_banner_hint_enabled: number;
  widget_banner_hint_color: string;
  widget_banner_hint_text: string;
  anyone_feature_hide_ressource: number;
  is_internal_widget_enabled: number;
  widget_title_required: number;
  widget_title_shown: number;
  widget_country_required: number;
  widget_country_shown: number;
  widget_show_resource_store_name: number;
  has_valid_coupons: number;
  is_group_appointment_store_feature_enabled: number;
  is_group_appointment_category_feature_enabled: number;
  is_widget_event_single_slot_preselect_enabled: number;
  widget_mobile_required: number;
  group_appointments_filter_behaviour: string;
  is_widget_service_shortcut_button_enabled: number;
  include_city_name_in_postcode_results: number;
  widget_show_free_slots_in_overview: number;
  is_captcha_feature_enabled: number;
  features: FeaturesDao[];
  widget_show_location_on_appointment_proposal: number;
  show_worker_selection_on_date_step: number;
  is_widget_postcode_worker_random_selection_enabled: number;
  is_custom_css_widget_enabled: number;
  show_widget_slot_location: number;
  widget_jumps_to_resources_step_when_single_service: number;
  has_bookable_workers: boolean; // Flag to identify partner has atleast single bookable worker
  is_appointment_reservation_enabled: number;
  split_personal_form_and_summary_in_widget: number;
  show_cross_selling_services_on_summary_step: number;
}

export class FeaturesDao {
  feature: FeatureDao;
  feature_id: number;
  id: number;
  partner_id: number;
  subscription_type_id: number;
  value: string;
}

export class FeatureDao {
  category: string;
  id: number;
  name: string;
  type: string;
  uuid: string;
}

export class CountryDao {
  id: number;
  supported: number;
  identifier: string;
}

export class WorkerDao {
  id: number;
  uuid: string;
  prename: string;
  lastname: string;
  full_name: string;
  email: string;
  store_id: number;
  avatar: string;
  bookable: number;
  booking_label: string;
  description: string;
  is_lead_generator: number;
  is_multi_language: number;
  is_resource: number;
  is_round_robin_enabled: number;
  job_title: string;
  resource_name: string;
  username: string;
  _translations: []
}

export class SubscriptionDao {
  id: number;
  partner_id: number;
  subscription_type_id: number;
  employees: number;
  reason: string;
  created: Date;
  start: Date;
  end: Date;
  status: number;
  automatic_renewal: number;
  invoice_url: string;
  payment_type: string;
  months: number;
  price: number;
  trial_mode: number;
  trial_reminder_sent: number;
  setup_price: any;
  worker_id: any;
  sales_provision: number;
}

export class AnalyticsDao {
  UA: string;
  GTM: string;
  AdobeLaunchTracking: string;
}

export class AppointmentAnalyticsDao {
  appointment_service_id: string;
  appointment_service_name: string;
  store_id: number | string;
  store_name: number | string;
  worker_id: number | string;
  resource_name: number | string;
  worker_email: number | string;
}

export class EventsAnalyticsDao {
  event_uuid: string | number;
  event_category: string;
  event_title: string;
  worker_id: number;
}

export class WidgetBookingInfoDao {
  id: number;
  identifier: string;
  description: string;
  type: string;
  salutation: string;
  intro: string;
  finish: string;
  footer: string;
  use_custom_logo: number;
  header_hex_color: string;
  courtesy_form: string;
  sms_content: string;
  _locale: string;
  _translations: {
    [key: string]: WidgetBookingInfoTranslation;
  };
}

export class WidgetBookingInfoTranslation {
  template_content: any;
  locale: string;
}
export class CustomBookingMessageTemplate {
  category: string;
  courtesy_form: string;
  description: string;
  finish: any;
  footer: any;
  id: number;
  identifier: string;
  intro: string;
  salutation: string;
  sms_content: string;
  template_content: string;
  template_type_id: number;
  type: string;
  is_multi_language: number;
  _translations: {
    [key: string]: CustomBookingMessageTemplateTranslation;
  };
}

interface CustomBookingMessageTemplateTranslation {
  locale: string;
  template_content: string;
  text_field_widget: string;
}
