import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from '../../../../../environments/environment';
import { APPOINTMENT_CONSTANT } from '../../../../constants/appointment-constants';
import { TRANSLATION_TEMPLATES } from '../../../../constants/translation-templates-constants';
import { PARENT_TO_CHILD_IFRAME_EVENTS, PARENT_TO_CHILD_IFRAME_EVENT_ACTIONS, PARENT_TO_CHILD_IFRAME_VALIDATION_CONTROL_NAME, WIDGET_CONSTANTS } from '../../../../constants/widget-constants';
import { UserDao } from '../../../../db-models/user-data-dao';
import { CustomBookingMessageTemplate } from '../../../../db-models/widget-conf-dao';
import { Partner } from '../../../../models/global';
import { AppointmentState } from '../../../../models/state.model';
import { WidgetColorConf } from '../../../../models/widget-color.model';
import { CustomEventService } from '../../../../services/custom-event.service';
import { HelperService } from '../../../../services/helper.service';
import { LoggerService } from '../../../../services/logger.service';
import { WidgetUtilService } from '../../../../services/widget-util.service';
import { ButtonComponent } from '../../../../shared/components/button/button.component';
import { TranslationPipe } from '../../../../shared/pipes/translation.pipe';
import { TrustHtmlPipe } from '../../../../shared/pipes/trust-html.pipe';
import { CwRadioTickButtonComponent } from '../../../common/theme/cw-radio-tick-button/cw-radio-tick-button.component';

@Component({
  selector: 'app-appointment-worker-card',
  templateUrl: './appointment-worker-card.component.html',
  styleUrls: ['./appointment-worker-card.component.scss'],
  standalone: true,
  imports: [FormsModule, NgClass, CwRadioTickButtonComponent, ButtonComponent,
    TranslationPipe, TrustHtmlPipe],
})
export class AppointmentWorkerCardComponent implements OnInit, OnChanges {

  @Input() lang: string;
  @Input() workersAvailable: UserDao[] = [];
  @Input() appointmentState: AppointmentState;
  @Input() widgetColorConf: WidgetColorConf;
  @Input() showEgalWer: boolean;
  @Input() partner: Partner;
  @Input() nextButtonTemplate: CustomBookingMessageTemplate;
  @Input() previousButtonTemplate: CustomBookingMessageTemplate;
  @Input() widgetTemplates: CustomBookingMessageTemplate[] = [];
  @Input() subscriptionTypeId: number;
  @Input() viewMode: string;

  @Output() navigateToEvent = new EventEmitter<any>();
  @Output() selectWorkerEvent = new EventEmitter<any>();
  @Output() workerSelectedEvent = new EventEmitter<any>();
  @Output() resetFormEvent = new EventEmitter<any>();

  readonly environment = environment;
  readonly appointmentConstant = APPOINTMENT_CONSTANT;
  readonly widgetConstant = WIDGET_CONSTANTS;
  readonly workerAvatarUrl = environment.workerAvatarUrl;
  readonly baseUrl = environment.baseUrl;
  readonly parentToChildIframeEvents = PARENT_TO_CHILD_IFRAME_EVENTS;
  readonly parentToChildIframeEventActions = PARENT_TO_CHILD_IFRAME_EVENT_ACTIONS;
  readonly parentToChildIframeValidationControlName = PARENT_TO_CHILD_IFRAME_VALIDATION_CONTROL_NAME;
  readonly templateContent = TRANSLATION_TEMPLATES;

  isMobile: boolean;
  widgetAnyoneLabelTemplate: CustomBookingMessageTemplate;
  widgetAnyoneDescTemplate: CustomBookingMessageTemplate;
  widgetResourceHeadingTemplate: CustomBookingMessageTemplate;

  constructor(
    private deviceDetectorService: DeviceDetectorService,
    private translate: TranslateService,
    private helperService: HelperService,
    private customEventService: CustomEventService,
    private widgetUtilService: WidgetUtilService,
  ) {
    this.translate.onLangChange.subscribe(lang => this.lang = lang.lang);
  }

  ngOnInit() {
    this.isMobile = this.deviceDetectorService.isMobile();
    this.workersAvailable.sort(this.helperService.sortArrayObjectsByField('resource_name'));
    this.appointmentState?.default_worker_id && this.selectWorker(this.appointmentState.default_worker_id);

    this.customEventService.iFrameNavigationFromParent.subscribe(action => {
      switch (action) {
        case this.parentToChildIframeEventActions.PREVIOUS:
          this.navigateTo(this.appointmentConstant.APPOINTMENT);
          break;

        case this.parentToChildIframeEventActions.NEXT:
          if (this.appointmentState.worker !== null) {
            this.navigateTo(this.appointmentConstant.DATE);
          } else if (this.appointmentState.worker === 0 || this.appointmentState.worker === null) {
            this.widgetUtilService.sendMessageToParent({
              eventName: this.parentToChildIframeEvents.VALIDATION,
              controls: [
                this.parentToChildIframeValidationControlName.WORKER
              ]
            });
          }
          break;
      }
    });

    // When length of storeDefaultWorkerIds is > 0 means, store has multiple mapped workers which needs to be filtered out first
    // before moving further
    if (this.appointmentState.storeDefaultWorkerIds?.length) {
      const filteredWorker = this.workersAvailable.filter(worker => this.appointmentState.storeDefaultWorkerIds.includes(worker.id));
      if (filteredWorker?.length) {
        LoggerService.warn('[Default store worker]: Default store workers are present so overwriting all the supported workers by default worker');
        this.workersAvailable  = filteredWorker;
      }
    }

    if (this.customEventService.previousPage !== APPOINTMENT_CONSTANT.DATE) {
      // When is_widget_postcode_worker_random_selection_enabled setting is enable and default worker from postcode
      // is null then worker selection will be random and selection will be skipped and if default worker is not null
      // then default will be selected selection will be skipped.
      if (Number(this.widgetUtilService.widgetConf.is_widget_postcode_worker_random_selection_enabled) === 1) {
        if (this.appointmentState.default_worker_id === null && this.workersAvailable?.length) {
          const randomIndex = Math.floor(Math.random() * this.workersAvailable.length);
          const workerId = this.workersAvailable[randomIndex].id;
          this.selectWorkerEvent.emit(workerId);
          this.navigateTo(this.appointmentConstant.DATE);
        } else if (this.appointmentState.default_worker_id) {
          this.selectWorkerEvent.emit(Number(this.appointmentState.default_worker_id));
          this.navigateTo(this.appointmentConstant.DATE);
        }
      } else {
        if (this.appointmentState.default_worker_id) {
          this.selectWorkerEvent.emit(Number(this.appointmentState.default_worker_id));
          this.navigateTo(this.appointmentConstant.DATE);
        }
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.widgetTemplates?.currentValue) {
      this.widgetTemplates?.length && this.setupTemplates();
    }
  }

  selectWorker(workerId: number): void {
    this.selectWorkerEvent.emit(workerId);
  }

  onWorkerSelected(): void {
    this.workerSelectedEvent.emit();
  }

  navigateTo(page: string): void {
    this.navigateToEvent.next(page);
  }

  resetForm(): void {
    this.resetFormEvent.emit();
  }

  setupTemplates(): void {
    this.widgetAnyoneLabelTemplate = this.widgetTemplates.find(template =>
      template.identifier === WIDGET_CONSTANTS.WIDGET_LABELS.WIDGET_ANYONE_LABEL
    );
    this.widgetAnyoneLabelTemplate && (this.widgetAnyoneLabelTemplate.is_multi_language = 1);

    this.widgetAnyoneDescTemplate = this.widgetTemplates.find(template =>
      template.identifier === WIDGET_CONSTANTS.WIDGET_LABELS.WIDGET_ANYONE_DESC_LABEL
    );
    this.widgetAnyoneDescTemplate && (this.widgetAnyoneDescTemplate.is_multi_language = 1);

    this.widgetResourceHeadingTemplate = this.widgetTemplates.find(template =>
      template.identifier === WIDGET_CONSTANTS.WIDGET_LABELS.WIDGET_RESOURCE_LABEL
    );
    this.widgetResourceHeadingTemplate && (this.widgetResourceHeadingTemplate.is_multi_language = 1);
  }
}
