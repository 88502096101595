import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { APPOINTMENT_CONSTANT } from '../../../../constants/appointment-constants';
import {
  DEFAULT_CUSTOMER_FIELDS, DEFAULT_CUSTOMER_PROFILES_ORDER, DEFAULT_REQUIRED_CUSTOMER_FIELDS,
  MEETING_TYPE_ID
} from '../../../../constants/widget-constants';
import { CustomFieldsDao, CustomerDbModel } from '../../../../db-models/appointment-custom-fields-dao';
import { StoresDao } from '../../../../db-models/stores-dao';
import { CustomBookingMessageTemplate, PartnerDao } from '../../../../db-models/widget-conf-dao';
import { CartItem } from '../../../../models/cart.model';
import { GlobalObjects } from '../../../../models/global';
import { MeetingTypeModel } from '../../../../models/meeting-types.model';
import { AppointmentState, UserState } from '../../../../models/state.model';
import { CustomerPrefillDataModel } from '../../../../models/widget-conf';
import { CustomEventService } from '../../../../services/custom-event.service';
import { FormsService } from '../../../../services/forms.service';
import { HelperService } from '../../../../services/helper.service';
import { LoggerService } from '../../../../services/logger.service';
import { PartnerService } from '../../../../services/partner.service';
import { UtilService } from '../../../../services/util.service';
import { WidgetUtilService } from '../../../../services/widget-util.service';
import { CustomerFormComponent } from '../../../common/customer-form/customer-form.component';
import { CustomfieldsComponent } from '../../../common/customfields/customfields.component';


@Component({
  selector: 'app-compact-personal-form',
  templateUrl: './compact-personal-form.component.html',
  styleUrls: ['./compact-personal-form.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  standalone: true,
  imports: [CustomerFormComponent, CustomfieldsComponent]
})
export class CompactPersonalFormComponent implements OnInit {

  @Input() customer_notification_preference: string;
  @Input() customFields: CustomFieldsDao[];
  @Input() customFieldValues: any;
  @Input() globals: GlobalObjects;
  @Input() isCustomFieldsLoaded = false;
  @Input() lang: string;
  @Input() partner: PartnerDao;
  @Input() store: StoresDao;
  @Input() selectedJsonCustomFields: {
    [key: number]: { type: string; value: any } | any;
  };
  @Input() selectedJsonCustomerDetail: CustomerPrefillDataModel;
  @Input() userState: UserState;
  @Input() meeting_type_id: string;
  @Input() userDataLSNameKey: string;
  @Input() widgetTemplates: CustomBookingMessageTemplate[] = [];
  @Input() appointmentCart: CartItem[] = [];
  @Input() appointmentState: AppointmentState;
  @Input() widgetType: string;
  @Input() customFieldValuesLSNameKey: string;

  @Output() updateUserStateEvent: EventEmitter<any> = new EventEmitter();
  @Output() updateCustomerSelectedEvent: EventEmitter<any> = new EventEmitter();

  readonly appointmentConstant = APPOINTMENT_CONSTANT;
  readonly meetingTypeId = MEETING_TYPE_ID;

  defaultCustomerFields: string[];
  defaultCustomerProfilesOrder: string[];
  defaultRequiredCustomerFields: string[];
  NOTIFICATION_TYPES: {
    value: string
    display: string,
  }[] = [];
  meetingTypes: MeetingTypeModel[];
  selectedCustomerId: number;
  customerSelected: any;
  customers: CustomerDbModel[];
  options: { id: number, text: string }[] = [];

  constructor(
    private customEventService: CustomEventService,
    private formService: FormsService,
    private helperService: HelperService,
    public partnerService: PartnerService,
    private translateService: TranslateService,
    private utilService: UtilService,
    private widgetUtilService: WidgetUtilService
  ) { }

  ngOnInit(): void {
    this.translateService.onLangChange.subscribe(lang => {
      this.lang = lang.lang;
    });

    if (this.widgetUtilService.zipCode) {
      this.userState.zip = this.widgetUtilService.zipCode;
    }

    this.defaultCustomerFields = DEFAULT_CUSTOMER_FIELDS;
    this.defaultRequiredCustomerFields = DEFAULT_REQUIRED_CUSTOMER_FIELDS;
    this.defaultCustomerProfilesOrder = DEFAULT_CUSTOMER_PROFILES_ORDER;

    this.setupNotificationType();
    this.renderCustomFields();
    this.getAppointmentMeetingTypes();
    this.setupInitialUserData();
  }

  private setupInitialUserData(): void {
    if (this.selectedJsonCustomerDetail) {
      if (this.selectedJsonCustomerDetail?.id) {
        this.getCustomerDataByUuid(this.selectedJsonCustomerDetail.id);
      } else {
        this.userState.title = Number(this.selectedJsonCustomerDetail.title);
        this.userState.forename = this.selectedJsonCustomerDetail.prename;
        this.userState.lastName = this.selectedJsonCustomerDetail.lastname;
        this.userState.gender = this.selectedJsonCustomerDetail.gender;
        this.userState.eMail = this.selectedJsonCustomerDetail.email;
        if (
          this.selectedJsonCustomerDetail.phone?.trim() &&
          !this.selectedJsonCustomerDetail.phone.includes('+')
        ) {
          this.selectedJsonCustomerDetail.phone = "+" + this.selectedJsonCustomerDetail.phone.trim();
        }
        this.userState.phone = this.selectedJsonCustomerDetail.phone;

        if (
          this.selectedJsonCustomerDetail.mobile?.trim() &&
          !this.selectedJsonCustomerDetail.mobile.includes('+')
        ) {
          this.selectedJsonCustomerDetail.mobile = "+" + this.selectedJsonCustomerDetail.mobile.trim();
        }
        this.userState.mobile = this.selectedJsonCustomerDetail.mobile;

        this.userState.company = this.selectedJsonCustomerDetail.company;
        this.userState.customerId = undefined;
        this.userState.customerUuid = undefined;
        this.userState.street = this.selectedJsonCustomerDetail.street;
        this.userState.zip = this.selectedJsonCustomerDetail.zip;
        this.userState.city = this.selectedJsonCustomerDetail.city;
        this.userState.country = this.selectedJsonCustomerDetail.country;
        this.userState.country_id = Number(this.selectedJsonCustomerDetail.country.id);
        this.userState.comment = this.selectedJsonCustomerDetail.comment;
        this.selectedCustomerId = undefined;
        this.customerSelected = undefined;
        this.initializeMeetingType();
        this.initializeCustomerNotificationPreference();
        this.updateCustomerSelected(this.customerSelected);
        this.updateUserState(this.userState);
      }
    }
  }

  private updateCustomerSelected(newCustomerSelected: any): void {
    this.updateCustomerSelectedEvent.emit(newCustomerSelected);
  }

  private updateUserState(newUserState: UserState): void {
    if (newUserState.customerUuid) {
      this.customEventService.customerChangedInInternalWidgetEvent.emit({ customerUuid: newUserState.customerUuid });
    }
    this.updateUserStateEvent.emit(newUserState);
  }

  private setupNotificationType(): void {
    if (this.partner?.widget_email_shown !== 1) {
      this.NOTIFICATION_TYPES = [{ display: 'sms', value: 'sms' }];
      if (this.partner && Number(this.partner.ask_for_customer_notification_preference) === 1) {
        this.initializeCustomerNotificationPreference();
        if (!this.userState.customer_notification_preference) {
          this.userState.customer_notification_preference = 'sms';
        }
      }
    } else {
      this.NOTIFICATION_TYPES = [
        { display: 'email', value: 'email' },
        { display: 'sms', value: 'sms' },
        { display: 'both_sms_email', value: 'both' }
      ];

      if (this.partner && Number(this.partner.ask_for_customer_notification_preference) === 1) {
        this.initializeCustomerNotificationPreference();
        if (!this.userState.customer_notification_preference) {
          this.userState.customer_notification_preference = 'email';
        }
      }
    }
  }

  private initializeCustomerNotificationPreference(): void {
    if (this.customer_notification_preference) {
      this.userState.customer_notification_preference = this.customer_notification_preference;
    }
  }

  renderCustomFields(): void {
    this.partnerService.customFieldsBefore = [];
    this.partnerService.customFieldsAfter = [];
    const customFieldsBefore: CustomFieldsDao[] = [];
    const customFieldsAfter: CustomFieldsDao[] = [];
    this.customFields.forEach(customField => {
      customField.position > 0 ? customFieldsAfter.push(customField) : customFieldsBefore.push(customField);
    });

    this.partnerService.customFieldsBefore = this.helperService.sortByNumber(customFieldsBefore, 'position');
    this.partnerService.customFieldsAfter = this.helperService.sortByNumber(customFieldsAfter, 'position');

    LoggerService.log('this.customFieldsBefore ', this.partnerService.customFieldsBefore);
    LoggerService.log('this.customFieldsAfter ', this.partnerService.customFieldsAfter);
  }

  private getAppointmentMeetingTypes(): void {
    let finalWorkerId: number;
    let serviceIds: number[] = [];

    if (this.appointmentCart && this.appointmentCart.length > 0) {
      let workerIds = this.appointmentCart.map(cartItem => cartItem.workerId);
      for (const cart of this.appointmentCart) {
        serviceIds = serviceIds.concat(cart.servicesIds);
      }
      workerIds = [...new Set(workerIds)];
      serviceIds = [...new Set(serviceIds)];
      if (workerIds.length === 1) {
        finalWorkerId = workerIds[0];
      }
    }
    this.getMeetingTypesByAppointmentServiceIds(finalWorkerId, serviceIds);
  }

  private getMeetingTypesByAppointmentServiceIds(finalWorkerId: number, serviceIds: number[]) {
    this.formService.getMeetingTypesByAppointmentServiceIds(finalWorkerId, serviceIds).subscribe({
      next: (meetingTypes) => {
        if (meetingTypes && meetingTypes.length > 0) {
          meetingTypes.forEach(meetingType => meetingType.is_multi_language = 1);
          this.meetingTypes = meetingTypes;
        } else {
          this.meetingTypes = [];
        }
        this.setupMeetingType();
      },
      error: (error: HttpErrorResponse) => {
        LoggerService.error(error);
      }
    });
  }

  private setupMeetingType(): void {
    if (this.meetingTypes?.length > 0) {
      if (!this.userState.meeting_type_id) {
        this.initializeMeetingType();
        if (!this.userState.meeting_type_id) {
          const defaultMeetingProvider = this.meetingTypes.find(meeting => meeting.is_default === 1);
          this.userState.meeting_type_id = defaultMeetingProvider ? defaultMeetingProvider.id : this.meetingTypes[0].id;
          this.userState.meetingType = defaultMeetingProvider ? defaultMeetingProvider : this.meetingTypes[0];
        }
      }
    } else {
      if (!this.userState.meeting_type_id) {
        this.userState.meeting_type_id = this.meetingTypeId.STORE;
      }
    }
  }

  private initializeMeetingType(): void {
    if (this.meeting_type_id && this.meetingTypes?.length > 0) {
      const foundedMeeting = this.meetingTypes.find(item => item.id === Number(this.meeting_type_id));
      if (foundedMeeting) {
        this.userState.meeting_type_id = Number(this.meeting_type_id);
        this.userState.meetingType = foundedMeeting;
      } else {
        this.userState.meeting_type_id = undefined;
        this.userState.meetingType = null;
      }
    }
  }

  private getCustomerDataByUuid(uuid: string): void {
    this.partnerService.getCustomersByUuid(uuid).subscribe({
      next: (result: { customer: CustomerDbModel }) => {
        if (result?.customer) {
          this.customerSelected = result.customer;
          this.customers = [Object.assign({}, result.customer)];
          this.options = this.getCustomersDropdownOptions();
          this.selectedCustomerId = result.customer.id;
          this.userState.forename = this.customerSelected['prename'];
          this.userState.lastName = this.customerSelected['lastname'];
          this.userState.gender = this.customerSelected['gender'];
          this.userState.eMail = this.customerSelected['email'];
          if (this.customerSelected['phone']) {
            this.userState.phone = this.customerSelected['phone'];
          }
          if (this.customerSelected['mobile']) {
            this.userState.mobile = this.customerSelected['mobile'];
          }
          this.userState.customerId = this.customerSelected['id'];
          this.userState.customerUuid = this.customerSelected['uuid'];
          this.userState.street = this.customerSelected['street'];
          this.userState.zip = this.customerSelected['zip'];
          this.userState.city = this.customerSelected['city'];
          this.initializeMeetingType();
          this.initializeCustomerNotificationPreference();
          this.updateCustomerSelected(this.customerSelected);
          this.updateUserState(this.userState);
        }
      },
      error: (error: HttpErrorResponse) => {
        LoggerService.error(error);
      }
    });
  }

  private getCustomersDropdownOptions(): { id: number, text: string }[] {
    let options: { id: number, text: string }[] = [];
    let text: string;

    if (this.customers && this.customers.length === 0) {
      return [];
    }

    for (const i in this.customers) {
      if (this.customers[i]) {
        text = '';
        if (this.customers[i]['company_name']) {
          text = text + this.customers[i]['company_name'];
        }

        if (this.customers[i]['email']) {
          text = text + ' (' + this.customers[i]['email'] + ')';
        }

        if (this.customers[i] && this.customers[i]['id']) {
          options.push({
            id: this.customers[i]['id'],
            text: text
          });
        }
      }
    }

    // sort array
    options = this.utilService.multiSort(options, ['text'], ['asc']);
    return options;
  }
}
